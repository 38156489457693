<button class='back' on:click>
	<svg fill='none' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'>
		<path d='m22 10-9 9 9 9' stroke='#2B4644' stroke-linecap='round' stroke-width='5' />
	</svg>
</button>

<style lang='scss'>
  .back {
    @include reset-button;

    width: 64px;
    height: 64px;
    position: absolute;
    top: 32px;
    left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 64px;
    background: #FDF2D3;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

    svg {
      width: 38px;
      height: 38px;
    }
  }
</style>
