<script lang='ts'>
	import { imask } from '@imask/svelte';
	import Template from './+template.svelte';
	import { bag, currentScreen, phone } from '../../stores';
	import MainButton from '../MainButton.svelte';
	import { Api } from '../../utils';

	const options = {
		mask: '{+7} 000 000-00-00',
		lazy: false,
	};

	let value = '';
	let mask;

	let accepted = false;
	let completed = false;

	function accept({ detail: maskRef }) {
		mask = maskRef;

		accepted = maskRef.unmaskedValue.length === 12;

		if (!accepted) completed = false;

		$phone = maskRef.unmaskedValue;
	}

	const complete = () => completed = true;

	const nextWithPhone = () => {
		Api.update();
		next();
		window.exponea.track('opora_smkt', {
			event_action: 'enter_number',
		});
	};

	const next = () => {
		currentScreen.results();
	};

	const reset = () => {
		if (!mask) return;

		mask.value = '';
	};
</script>

<Template class='-gradient-bg'>
	<div class='content'>
		<div class='name'>{$bag.name},</div>
		<div class='caption'>
			Введите ваш номер телефона, чтобы мы могли связаться с вами в случае победы
		</div>
		<form>
			<fieldset>
				<input on:accept={accept} on:complete={complete} type='text' use:imask={options} {value}>
				<button on:click={reset} type='button'>
					<svg fill='none' viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'>
						<path clip-rule='evenodd'
									d='M12.5 24c6.627 0 12-5.373 12-12s-5.373-12-12-12S.5 5.373.5 12s5.373 12 12 12Zm4-6.586-3.793-3.793-3.793 3.793L7.5 16l3.793-3.793L7.5 8.414 8.914 7l3.793 3.793L16.5 7l1.414 1.414-3.793 3.793L17.914 16 16.5 17.414Z'
									fill='#2B4644'
									fill-rule='evenodd' />
					</svg>
				</button>
			</fieldset>
		</form>
		{#if accepted && completed}
			<MainButton click='{nextWithPhone}'>Далее</MainButton>
		{/if}
		<div class='skip'>
			<button on:click={next} type='button'>Продолжить</button>
			без ввода номера
		</div>
	</div>
</Template>

<style lang='scss'>
  .content {
    width: 100%;
    margin-top: 83px;
    display: flex;
    flex-direction: column;
  }

  .name {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }

  .caption {
    margin-top: 16px;
    font-size: 20px;
    line-height: 25px;
  }

  form {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;

    > fieldset {
      margin: 0;
      padding: 0;
      position: relative;
      border: none;
      outline: none;

      input {
        width: 100%;
        height: 60px;
        padding: 15px 16px 15px 16px;
        font-family: $Euclid, sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.7);
        background: #FDF2D3;
        border-radius: 24px;
        box-sizing: border-box;
        border: none;
        outline: none;
      }

      button {
        @include reset-button;
        position: absolute;
        top: 18px;
        right: 16px;

        svg {
          width: 25px;
          height: 24px;
        }
      }
    }
  }

  .skip {
    margin-top: 24px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    button {
      @include reset-button;
      text-decoration-line: underline;
    }
  }
</style>
