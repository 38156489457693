<script lang='ts'>
	import Template from './+template.svelte';
	import Moon from '../Moon.svelte';
	import Text from '../customization-steps/Text.svelte';
	import MainButton from '../MainButton.svelte';
	import { currentScreen } from '../../stores';
	import Image from '../Image.svelte';

	const next = () => {
		currentScreen.customization();

		window.exponea.track('opora_smkt', {
			event_action: 'game_step1',
		});
	};
</script>

<Template class='-gradient-bg'>
	<Moon />
	<div class='intro'>
		<div class='title'>
			Знакомьтесь!
		</div>
		<div class='preview'>
			<Image src='bag/Start_01.png' />
		</div>
		<Text
			subtitle='Вы сможете выбрать её облик и подобрать талисман.'
			title='Это Сумка Самоката — главная героиня игры.'
		/>
		<MainButton click='{next}'>Продолжить</MainButton>
	</div>
</Template>

<style lang='scss'>
  .intro {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .title {
      margin-top: 16px;
      font-size: 24px;
      line-height: 140%;
      font-weight: 700;
      letter-spacing: 0;
      text-align: center;
    }

    .preview {
      position: absolute;
      top: 110px;
      left: 0;
      right: 0;
      display: flex;

      :global(picture) {
        width: auto;
        height: 323px;
        margin: auto;
      }
    }

    :global(> button) {
      margin-top: auto !important;

      @include breakpoint-up($tablet) {
        margin-top: 500px !important;
      }
    }
  }
</style>
