import { Actor, CollisionType, PolygonCollider, Vector } from 'excalibur';
import { res } from '../res';
import { random } from '../utils';
import { statueCollisionGroup } from '../collision';
import { clouds } from '../colliders';

export default class Cloud extends Actor {
	constructor(props) {
		const cloudIndex = random.integer(0, 1);
		const sprite = [
			res.scene.getFrameSprite('scene/col4'),
			res.scene.getFrameSprite('scene/col5'),
		][cloudIndex];
		const colliderConfig = clouds[cloudIndex];

		super({
			...props,
			name: 'cloud',
			width: sprite.width,
			height: sprite.height,
			anchor: new Vector(0.5, 1),
			collisionGroup: statueCollisionGroup,
			collisionType: CollisionType.Passive,
			collider: new PolygonCollider({
				points: colliderConfig.polygon.map(
					({ x, y }) => new Vector(x - sprite.width / 2 + colliderConfig.x, y),
				),
			}),
			z: 1,
		});

		this.graphics.use(sprite);
	}
}
