import { debounce } from 'throttle-debounce';
import { Color, DisplayMode, Engine, Loader, Logger, LogLevel, Physics } from 'excalibur';
import { res } from './res';
import Training from './scenes/training';
import { SCENES } from './enum';
import config from './config';
import { Pane } from 'tweakpane';
import type { SceneData } from '../types';
import { get } from 'svelte/store';
import { bag, menu, wrongOrientationScreen } from '../stores';
import { easyTween } from './utils';
import Challenge from './scenes/challenge';
import GameScene from './partials/game-scene';
import { sawResults } from '../utils';

class Game extends Engine {
	sound = true;
	isPaused = false;
	lastInputTime: DOMHighResTimeStamp;

	constructor() {
		super({
			viewport: {
				width: 390,
				height: 672,
			},
			resolution: {
				width: 390 * 2,
				height: 672 * 2,
			},
			configurePerformanceCanvas2DFallback: {
				allow: true,
				threshold: {
					fps: 20,
					numberOfFrames: 100,
				},
			},
			pixelRatio: 1,
			canvasElementId: 'game',
			displayMode: DisplayMode.FitContainerAndFill,
			suppressPlayButton: true,
			suppressConsoleBootMessage: true,
			backgroundColor: Color.fromRGB(21, 21, 21),
		});

		Physics.gravity.setTo(0, config.gravityY);
		Logger.getInstance().defaultLevel = LogLevel.Error;

		this.addScene(SCENES.TRAINING, new Training());
		this.addScene(SCENES.CHALLENGE, new Challenge());

		config.showFps && this.showFpsCounter();
		config.debug && this.activateDebug();

		this.start();
	}

	onInitialize() {
		window.addEventListener('resize', () => {
			this.canvas.style.opacity = '0';
			const wrongOrientation = this.handleScreenOrientation();

			if (wrongOrientation) {
				this.togglePause(true);
			}
		});
		window.addEventListener(
			'resize',
			debounce(500, async () => {
				const wrongOrientation = this.handleScreenOrientation();

				if (!wrongOrientation) {
					requestAnimationFrame(() => {
						this.canvas.style.opacity = '1';
						this.togglePause(false);
					});
				}
			}),
		);
	}

	useCanvas2DFallback() {}

	startMusic() {
		res.sounds.music.loop = true;
		!res.sounds.music.isPlaying() && res.sounds.music.play();
	}

	stopMusic() {
		res.sounds.music.stop();
		res.sounds.music2.stop();
	}

	toggleSound() {
		this.sound = !this.sound;

		if (this.sound) {
			Object.values(res.sounds).forEach(sound => {
				sound.volume = 1;
			});
		}

		if (!this.sound) {
			Object.values(res.sounds).forEach(sound => {
				sound.volume = 0;
			});
		}
	}

	togglePause(val: boolean) {
		this.isPaused = val;
		const currentScene = <GameScene>this.currentScene;
		currentScene.togglePlayer && currentScene.togglePlayer(val);
	}

	load(): Promise<void> {
		const loader = new Loader([res.player, res.scene, ...Object.values(res.sounds)]);
		loader.suppressPlayButton = true;

		return super.load(loader);
	}

	async play() {
		this.goToScene(SCENES.TRAINING, <SceneData>{
			bag: get(bag),
		});
	}

	async playChallenge() {
		this.goToScene(SCENES.CHALLENGE, <SceneData>{
			bag: get(bag),
			autostart: sawResults(),
		});
	}

	waitFor(time: number): Promise<void> {
		return new Promise(res => {
			this.clock.schedule(() => res(), time);
		});
	}

	async curtainFx(cb: () => Promise<void>, duration = 500) {
		await easyTween(
			progress => this.canvas.style.setProperty('opacity', (1 - progress).toString()),
			duration,
		);

		await cb();

		await easyTween(
			progress => this.canvas.style.setProperty('opacity', progress.toString()),
			duration,
		);
	}

	startCurrentScene() {
		(<GameScene>this.currentScene).start();
	}

	onPostUpdate(_engine: Engine, _delta: number) {
		if (
			this.lastInputTime &&
			performance.now() - this.lastInputTime > config.inputTimeout
		) {
			this.lastInputTime = null;
			menu.open();
		}
	}

	private handleScreenOrientation() {
		const wrongOrientation = matchMedia(
			'(pointer: coarse) and (max-width: 1024px) and (orientation: landscape)',
		).matches;

		wrongOrientationScreen.set(wrongOrientation);

		return wrongOrientation;
	}

	private showFpsCounter() {
		const fpsPane = new Pane();
		fpsPane.addBinding(this.clock.fpsSampler, 'fps', {
			readonly: true,
			view: 'graph',
			min: 20,
			max: 60,
		});
	}

	private activateDebug() {
		this.debug.entity.showId = false;
		this.debug.collider.showGeometry = true;
		this.debug.transform.showPosition = true;

		this.showDebug(true);
	}
}

const game = new Game();
export default game;
