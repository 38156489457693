export enum SCENES {
	PLAY = 'play',
	TRAINING = 'training',
	CHALLENGE = 'challenge',
}

export enum GAME_EVENTS {
	RESTART = 'restart',
	SCORE = 'score',
	GAME_OVER = 'gameOver',
}
