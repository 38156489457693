<div class='orientation-informer'>
	<div class='text'>Поверните экран</div>
</div>

<style lang='scss'>
  .orientation-informer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #000000;
  }

  .text {
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    transform: rotate(-90deg);
  }
</style>
