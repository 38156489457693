<script lang='ts'>
	import { bag, currentLives } from '../stores';
	import type { BagCustomization } from '../types';
	import Image from './Image.svelte';

	let url = `lives-icons/live${(<BagCustomization>$bag).totem + 1}.png`;
</script>

<div class='lives'>
	{#if $currentLives}
		{#each new Array($currentLives || 3) as live}
			<Image src='{url}' />
		{/each}
	{/if}
</div>

<style lang='scss'>
  .lives {
    display: flex;
    align-items: center;

    :global(img) {
      width: 48px;
      height: auto;
    }
  }
</style>
