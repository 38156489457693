<script lang='ts'>
	import { assetsUrl } from '../utils';

	let className = null;

	export let src;
	export let style = null;
	export { className as class };
</script>

<picture class='{className}' {style}>
	<source srcset='{assetsUrl(src.replace(".png", ".webp"))}'
					type='image/webp'>
	<img src='{assetsUrl(src)}'>
</picture>

<style lang='scss'>
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
