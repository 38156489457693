import { Actor, CollisionType, PolygonCollider, Sprite, Vector } from 'excalibur';
import { res } from '../res';
import { statueCollisionGroup } from '../collision';
import { random } from '../utils';
import { columns } from '../colliders';

export default class Statue extends Actor {
	private readonly sprite!: Sprite;

	constructor(props) {
		const colIndex = random.integer(0, 2);
		const sprite = [
			res.scene.getFrameSprite('scene/col1'),
			res.scene.getFrameSprite('scene/col2'),
			res.scene.getFrameSprite('scene/col3'),
		][colIndex];
		const colliderConfig = columns[colIndex];

		super({
			...props,
			name: 'statue',
			width: sprite.width,
			height: sprite.height,
			anchor: new Vector(0.5, 0),
			collisionGroup: statueCollisionGroup,
			collisionType: CollisionType.Passive,
			collider: new PolygonCollider({
				points: colliderConfig.polygon.map(
					({ x, y }) => new Vector(x - sprite.width / 2 + colliderConfig.x, y),
				),
			}),
			z: 1,
		});

		this.sprite = sprite;
	}

	onInitialize() {
		this.graphics.use(this.sprite);
	}
}
