<script>
	import Button from './Button.svelte';
	import { links } from '../texts';

	const track = () => {
		window.exponea.track('opora_smkt', {
			event_action: 'game_get_promocode',
		});
	};
</script>

<div class='prize-button'>
	<Button
		href='{links.promo}' on:click={track}>
		Забрать приз
	</Button>
</div>

<style lang='scss'>
  .prize-button {
    margin-top: 24px;
    position: relative;

    @include breakpoint-up($tablet) {
      width: 326px;
      margin-left: auto;
      margin-right: auto;
    }

    &:after {
      content: 'Скидка 7%';
      padding: 4px 8px 4px 8px;
      position: absolute;
      top: -12px;
      right: 0;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      background: rgba(253, 242, 211, 1);
      border-radius: 8px;
      transform: rotate(-3deg);
    }
  }
</style>
