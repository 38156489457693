<script lang='ts'>
	import Template from './+template.svelte';
	import { bag, currentScreen, myBestResults, phone, score } from '../../stores';
	import MainButton from '../MainButton.svelte';
	import { get } from 'svelte/store';
	import { Api, maskPhone } from '../../utils';
	import { onMount } from 'svelte';
	import Loader from '../Loader.svelte';

	const playChallenge = () => {
		currentScreen.playChallenge(true);

		window.exponea.track('opora_smkt', {
			event_action: 'improve_result',
		});
	};

	let scoreboard;

	const prev = () => {
		currentScreen.phone();
	};

	onMount(async () => {
		scoreboard = await Api.results();
	});
</script>

<Template class='-gradient-bg'>
	<div class='content'>
		<div class='name'>
			{get(bag).name},
		</div>
		<div class='caption'>
			вы набрали
		</div>
		<div class='scores'>
			{get(score)} <span>Очков</span>
		</div>
		{#if $myBestResults}
			<div class='best-result'>
				<div class='bg'></div>
				<div class='place'>
					{$myBestResults.position}
				</div>
				<div class='result'>
					<div class='best-value'>
						<span>Ваш лучший результат</span><br>
						{get(bag).name}<br>
						{maskPhone($phone)}
					</div>

					{#if !$phone}
						<div class='caption' on:click={prev}>
							Ввести номер телефона <br>и <span>сохранить результат</span>
						</div>
					{/if}
				</div>
				<div class='score'>
					{$myBestResults.result}
				</div>
			</div>
		{/if}
		{#if scoreboard}
			<ul class='leaderboard'>
				{#each scoreboard as player, index}
					<li class:hide='{ index===0 && $myBestResults && $myBestResults.position===1 }'>
						<div class='place'>{index + 1}</div>
						<div class='player'>
							{player.name} <br>
							{maskPhone(player.phone)}
						</div>
						<div class='score'>
							{player.result}
						</div>
					</li>
				{/each}
			</ul>
		{:else}
			<Loader />
		{/if}
	</div>
	<MainButton click='{playChallenge}'>Улучшить результат</MainButton>
</Template>

<style lang='scss'>
  .content {
    width: 100%;
    margin-top: 83px;
    margin-bottom: auto;

    > .name {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
    }

    > .caption {
      margin-top: 8px;
      font-size: 24px;
      line-height: 30px;
    }

    > .scores {
      margin-top: 12px;
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;

      span {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    > .best-result {
      margin-top: 27px;
      position: relative;
      padding: 16px 8px 16px 47px;
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      color: #000;

      > .bg {
        position: absolute;
        left: -8px;
        right: -8px;
        top: 0;
        bottom: 0;
        background: rgba(253, 242, 211, 1);
        border-radius: 24px;
        z-index: -1;
      }

      > .place {
        position: absolute;
        top: 19px;
        left: 8px;
        font-size: 18px;
        line-height: 24px;
      }

      > .result {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;

        .best-value {
          > span {
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
          }
        }

        > .caption {
          margin-left: auto;
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #000000;

          br {
            @include breakpoint-up($tablet) {
              display: none;
            }
          }

          > span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      > .score {
        flex-shrink: 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        text-align: right;
      }
    }

    > .leaderboard {
      margin: 16px 0 0;
      padding: 0;
      list-style: none;
      counter-reset: place;

      li {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 47px;
        position: relative;
        display: flex;
        justify-content: space-between;
        column-gap: 10px;

        & + li {
          border-top: 1px solid #FFF;
        }

        .place {
          position: absolute;
          top: 12px;
          left: 8px;
        }

        .player {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
        }

        .score {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          text-align: right;
        }

        &.hide {
          display: none;

          & + li {
            border-top: none;
          }
        }
      }
    }
  }
</style>
