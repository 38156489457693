<script lang='ts'>
	import Button from './Button.svelte';
	import Image from './Image.svelte';
	import { bonusInfo } from '../stores';
	import game from '../game/game';

	const accept = async () => {
		bonusInfo.set(false);
		await game.startCurrentScene();
	};
</script>

<div class='bonus-popup'>
	<div class='content'>
		<Image src='bonus.png' />
		<div class='text'>
			<div class='title'>Ловите волшебные сладости</div>
			Мёд от Самоката даёт дополнительную попытку, а наши орешки с варёной сгущёнкой — 300 очков
		</div>
		<Button on:click={accept}>Вперед</Button>
	</div>
</div>

<style lang='scss'>
  .bonus-popup {
    padding: 16px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;

    .content {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      padding: 16px;
      padding-top: 30px;
      background: rgba(253, 242, 211, 1);
      border-radius: 24px;
      box-sizing: border-box;

      > :global(picture) {
        width: 100%;
        height: auto;
        aspect-ratio: 652 / 360;
      }

      > .text {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 140%;
        color: #000;

        > .title {
          font-weight: 700;
          line-height: 30px;
        }
      }
    }
  }
</style>
