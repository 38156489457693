import type { ActorArgs } from 'excalibur';
import { CollisionType, Sprite, vec } from 'excalibur';
import { pointsCollisionGroup } from '../collision';
import { random } from '../utils';
import { res } from '../res';
import Item from '../partials/item';
import { hints as hText } from '../../texts';
import { hints, lastObjectName } from '../../stores';
import ScoreInformer from './score-informer';

export default class Point extends Item {
	static hintsUsed = [];
	used = false;
	public currentGraphicsName!: string;
	private readonly currentGraphicsSprite!: Sprite;

	constructor(
		props: ActorArgs,
		private exclude = [],
	) {
		let graphics = ['point1', 'point2', 'point3', 'point4', 'point5', 'point6'];

		if (exclude) graphics = graphics.filter(name => !exclude.includes(name));

		let name = random.pickOne(graphics);
		let sprite = res.scene.getFrameSprite(`scene/${name}`);

		super({
			...props,
			width: sprite.width - 20,
			height: sprite.height - 20,
			name,
			collisionGroup: pointsCollisionGroup,
			collisionType: CollisionType.Passive,
		});

		this.currentGraphicsName = name;
		this.currentGraphicsSprite = sprite;
	}

	async use() {
		if (this.used) return;
		this.used = true;

		if (!Point.hintsUsed.includes(this.name)) {
			const index = parseInt(this.name.replace('point', '')) - 1;

			hints.add({
				img: `${this.name}.png`,
				text: hText[index],
			});

			Point.hintsUsed.push(this.name);
		}

		const si = new ScoreInformer({
			pos: this.pos.sub(vec(-this.width / 2, this.height)),
		});

		this.scene.add(si);

		lastObjectName.set(this.currentGraphicsName);

		res.sounds.catch.play();
		await super.use();
	}

	protected addGraphics() {
		this.graphics.use(this.currentGraphicsSprite);
	}
}
