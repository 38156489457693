<script lang='ts'>
	import { fade } from 'svelte/transition';
	import { customizationSteps } from '../../stores';
	import Template from './+template.svelte';
	import { onMount } from 'svelte';
	import type { CustomizationStepsStore } from '../../types';
	import Moon from '../Moon.svelte';

	let currentStepComponent;

	const updateCurrentStepComponent = () => {
		currentStepComponent = (<CustomizationStepsStore>$customizationSteps).component;
	};

	onMount(() => {
		updateCurrentStepComponent();
	});
</script>

<Template class='-gradient-bg'>
	<Moon />

	{#if $customizationSteps.component === currentStepComponent}
		<div class='customization-steps-container' on:outroend={updateCurrentStepComponent} transition:fade>
			<svelte:component this={currentStepComponent} />
		</div>
	{/if}
</Template>

<style lang='scss'>
  .customization-steps-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
</style>
