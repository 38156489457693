<script lang='ts'>
	import Template from './+template.svelte';
	import ActionCaption from '../ActionCaption.svelte';
	import { currentScreen } from '../../stores';
	import MainButton from '../MainButton.svelte';
	import Groznov from '../Groznov.svelte';
	import PrizesImg from '../PrizesImg.svelte';
	import game from '../../game/game';

	const play = async () => {
		await game.play();
		currentScreen.play();
		requestAnimationFrame(() => game.startCurrentScene());
	};
</script>

<Template class='-bg'>
	<div class='title'>
		Попытки закончились
	</div>
	<div class='text'>
		Но вы можете перезапустить игру и попробовать ещё раз. Пусть всё получится!
	</div>
	<PrizesImg />
	<div class='button-wrapper'>
		<MainButton click='{play}'>Начать заново</MainButton>
	</div>
	<ActionCaption />
	<Groznov />
</Template>

<style lang='scss'>
  .title {
    font-size: 32px;
    font-weight: 500;
    line-height: 41px;
  }

  .intro {
    margin-top: 16px;
    font-size: 20px;
    line-height: 25px;

    .name {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .button-wrapper {
    margin-top: 16px;
    margin-bottom: auto;

    @include breakpoint-up($tablet) {
      width: 326px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .text {
    margin-top: 24px;
    font-size: 20px;
    line-height: 25px;

    a {
      @include reset-link;
      text-decoration: underline;
    }
  }
</style>
