<script lang='ts'>
	import Template from './+template.svelte';
	import { getRandomName } from '../../utils';
	import { onMount } from 'svelte';
	import { bag, customizationSteps } from '../../stores';
	import type { BagCustomization } from '../../types';
	import BackButton from '../BackButton.svelte';
	import Image from '../Image.svelte';

	const roll = () => {
		(<BagCustomization>$bag).name = getRandomName();
	};

	onMount(() => {
		if (!(<BagCustomization>$bag).name) {
			roll();
		}
	});
</script>

<Template>
	<BackButton on:click={customizationSteps.prev} />

	<div class='title'>Выберите имя персонажа</div>
	<div class='preview'>
		<Image src='bag/Form_0{$bag.form+1}.png' />
		<Image src='face/Emotion_0{$bag.face+1}.png' />
	</div>
	<div class='name'>
		<div class='input'>{$bag.name}</div>
		<button on:click={roll}>
			<svg fill='none' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M24.217 7.087c-5.093-4.555-12.738-4.566-17.421.117-4.858 4.858-4.68 12.912.396 17.988 5.077 5.077 13.13 5.255 17.988.397l-3.244-3.244c-3.143 3.143-8.354 3.028-11.64-.257-3.284-3.285-3.4-8.496-.256-11.64 2.968-2.968 7.78-3.027 11.072-.257l-3.543 3.544 10.475.23-.231-10.474-3.596 3.596Z'
					fill='#231F20'
					opacity='.9' />
			</svg>
		</button>
	</div>
</Template>

<style lang='scss'>
  .preview {
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    :global(picture) {
      width: auto;
      height: 320px;
      margin: auto;
      aspect-ratio: 608 / 640;

      &:last-child {
        position: absolute;
      }
    }
  }

  .name {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 32px;
    right: 32px;
    top: 466px;
    border: none;

    .input {
      width: 100%;
      padding: 15px 42px 15px 16px;
      display: flex;
      flex-wrap: wrap;
      font-size: 22px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.7);
      border-radius: 24px;
      border: none;
      outline: none;
      background: rgba(253, 242, 211, 1);
      box-sizing: border-box;
    }

    @include breakpoint-up($tablet) {
      max-width: 326px;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      @include reset-button;

      width: fit-content;
      height: fit-content;
      position: absolute;
      top: 15px;
      bottom: 15px;
      right: 15px;
      display: flex;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
</style>
