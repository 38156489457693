export default {
	debug: false,
	showFps: false,
	gravityY: 250,
	jumpForce: 250,
	scrollSpeed: 350,
	scrollSpeedMax: 1000,
	scrollSpeedMultiply: 0.35,
	statueOffset: 2000,
	statueGap: 250,
	trainingObjectCount: 15,
	pointsBoost: 300,
	scenePreloadItems: 3,
	hintsTimeout: 3000,
	inputTimeout: 20000,
	playerYBounds: 1000,
};
