import { Actor, Axis, Camera, Engine, LockCameraToActorAxisStrategy, Vector } from 'excalibur';

export class LockCameraToActorAxisWithOffsetStrategy extends LockCameraToActorAxisStrategy {
	constructor(
		public target: Actor,
		public axis: Axis,
		public offset: number = 0,
	) {
		super(target, axis);
	}

	public action = (target: Actor, cam: Camera, _eng: Engine, _delta: number) => {
		const center = target.center;
		const currentFocus = cam.getFocus();

		if (this.axis === Axis.X) {
			return new Vector(center.x - this.offset, currentFocus.y);
		} else {
			return new Vector(currentFocus.x - this.offset, center.y);
		}
	};
}
