export const names = {
	m: {
		first: [
			'Харизматичный',
			'Спокойный',
			'Энергичный',
			'Милый',
			'Проворный',
			'Лаконичный',
			'Утончённый',
			'Добрый',
			'Дружелюбный',
			'Острый',
			'Креативный',
			'Активный',
			'Волшебный',
			'Ловкий',
			'Динамичный',
			'Вдохновлённый',
			'Гармоничный',
			'Серьёзный',
			'Смелый',
			'Ответственный',
			'Романтичный',
			'Практичный',
			'Подвижный',
			'Аристократичный',
			'Беззаботный',
			'Брутальный',
			'Вежливый',
			'Коварный',
			'Благородный',
			'Гордый',
			'Гостеприимный',
			'Деловой',
			'Музыкальный',
			'Танцующий',
			'Душевный',
			'Задумчивый',
			'Идейный',
			'Манерный',
			'Солнечный',
			'Деятельный',
			'Скромный',
			'Дисциплинированный',
			'Импульсивный',
			'Громкий',
			'Оригинальный',
			'Неординарный',
			'Нарядный',
			'Решительный',
			'Разумный',
			'Чуткий',
			'Драматичный',
			'Хмурый',
			'Впечатлительный',
			'Впечатляющий',
			'Солидный',
			'Убедительный',
			'Искренний',
			'Дерзкий',
			'Поэтичный',
			'Мудрый',
			'Умный',
			'Комичный',
			'Музыкальный',
			'Сонный',
			'Позитивный',
			'Скромный',
			'Стеснительный',
			'Прыткий',
			'Радостный',
			'Эффектный',
			'Обаятельный',
			'Ранимый',
			'Рациональный',
			'Эмоциональный',
			'Легендарный',
			'Бесстрашный',
			'Самостоятельный',
			'Сентиментальный',
			'Тактичный',
			'Трудолюбивый',
			'Искренний',
			'Мягкий',
			'Изобретательный',
			'Стильный',
			'Модный',
			'Стремительный',
			'Фотогеничный',
			'Жизнерадостный',
			'Загадочный',
			'Пунктуальный',
			'Романтичный',
			'Хозяйственный',
			'Компанейский',
			'Целеустремлённый',
			'Дружелюбный',
			'Изящный',
			'Чуткий',
			'Эпатажный',
			'Счастливый',
			'Свободный',
			'Знаменитый',
			'Творческий',
			'Довольный',
			'Уважаемый',
			'Типичный',
			'Славный',
			'Забавный',
			'Усталый',
			'Шумный',
			'Молчаливый',
		],
		second: [
			'Дрип',
			'Круассан',
			'Творожок',
			'Сырок',
			'Йогурт',
			'Кефир',
			'Смузи',
			'Мусс',
			'Коктейль',
			'Булгур',
			'Рис',
			'Нут',
			'Ванилин',
			'Кофе',
			'Чай',
			'Горошек',
			'Шоколад',
			'Рахат-лукум',
			'Батончик',
			'Кекс',
			'Крекер',
			'Зефир',
			'Мармелад',
			'Попкорн',
			'Багет',
			'Шорли',
			'Энергетик',
			'Пряник',
			'Попкорн',
			'Квас',
		],
	},
	w: {
		first: [
			'Харизматичная',
			'Спокойная',
			'Энергичная',
			'Милая',
			'Проворная',
			'Лаконичная',
			'Утончённая',
			'Добрая',
			'Дружелюбная',
			'Острая',
			'Креативная',
			'Активная',
			'Волшебная',
			'Ловкая',
			'Динамичная',
			'Вдохновлённая',
			'Гармоничная',
			'Серьёзная',
			'Смелая',
			'Ответственная',
			'Романтичная',
			'Практичная',
			'Подвижная',
			'Аристократичная',
			'Беззаботная',
			'Брутальная',
			'Вежливая',
			'Коварная',
			'Благородная',
			'Гордая',
			'Гостеприимная',
			'Деловая',
			'Музыкальная',
			'Танцующая',
			'Душевная',
			'Задумчивая',
			'Идейная',
			'Манерная',
			'Солнечная',
			'Деятельная',
			'Скромная',
			'Дисциплинированная',
			'Импульсивная',
			'Громкая',
			'Оригинальная',
			'Неординарная',
			'Нарядная',
			'Решительная',
			'Разумная',
			'Чуткая',
			'Драматичная',
			'Хмурая',
			'Впечатлительная',
			'Впечатляющая',
			'Солидная',
			'Убедительная',
			'Искренняя',
			'Дерзкая',
			'Поэтичная',
			'Мудрая',
			'Умная',
			'Комичная',
			'Музыкальная',
			'Сонная',
			'Позитивная',
			'Скромная',
			'Стеснительная',
			'Прыткая',
			'Радостная',
			'Эффектная',
			'Обаятельная',
			'Ранимая',
			'Рациональная',
			'Эмоциональная',
			'Легендарная',
			'Бесстрашная',
			'Самостоятельная',
			'Сентиментальная',
			'Тактичная',
			'Трудолюбивая',
			'Искренняя',
			'Мягкая',
			'Изобретательная',
			'Стильная',
			'Модная',
			'Стремительная',
			'Фотогеничная',
			'Жизнерадостная',
			'Загадочная',
			'Пунктуальная',
			'Романтичная',
			'Хозяйственная',
			'Компанейская',
			'Целеустремлённая',
			'Дружелюбная',
			'Изящная',
			'Чуткая',
			'Эпатажная',
			'Счастливая',
			'Свободная',
			'Знаменитая',
			'Творческая',
			'Довольная',
			'Уважаемая',
			'Типичная',
			'Славная',
			'Забавная',
			'Усталая',
			'Шумная',
			'Молчаливая',
		],
		second: [
			'Слойка',
			'Булочка',
			'Каша',
			'Моцарелла',
			'Рикотта',
			'Страчателла',
			'Сметана',
			'Ряженка',
			'Вермишель',
			'Лапша',
			'Паста',
			'Чечевица',
			'Мука',
			'Матча',
			'Фасоль',
			'Гранола',
			'Горчица',
			'Паприка',
			'Аджика',
			'Конфета',
			'Вафля',
			'Трубочка',
			'Пастила',
			'Зефирка',
			'Комбуча',
		],
	},
};

export const totems = [
	['Связка приправ', 'Чтобы откладывать на полку заготовки, а не приятные дела'],
	['Краска', 'Чтобы осень была наполнена взаимопомощью'],
	[
		'Увеличительное стекло',
		'Чтобы ответы на вопросы в квизах всегда приходили мгновенно',
	],
	['Транспортир', 'Чтобы все жизненные задачи казались лёгкими'],
	['Чай с мятой от Самоката', 'Чтобы согреваться холодными вечерами'],
	['Компас', 'Чтобы каждый день находить новые маршруты'],
];

export const forms = [
	['Коробка дрипов', 'Зарядит энергией на всю игру'],
	['Рахат-лукум с гранатом и фундуком', 'Сделает игру запоминающейся'],
	['Банка с закрутками', 'Будет ловко крутиться и поможет преодолеть препятствия'],
	['Ванильный зефир', 'Найдёт способ собрать нужные предметы'],
	['Хлебные чипсы с луком', 'Помогут продвинуться к цели'],
	['Рюкзак путешественника', 'Выручит в любой ситуации — в нём есть всё'],
];

export const hints = [
	'Чтобы все домашние ужины были уютными',
	'Иногда найти опору помогают самые простые вещи',
	'Вы поймали отличный вечер с друзьями',
	'Пусть всё будет просто как дважды два',
	'Энергия мармеладных мишек от Самоката активирована!',
	'Вы выбрали верный маршрут!',
	'Вы поймали орешки с варёной сгущёнкой от Самоката — держите дополнительные 300 очков',
	'Вы поймали цветочный мёд от Самоката — держите дополнительную попытку',
];

export const links = {
	policy: 'https://samokat.ru/privacy',
	promo:
		'https://samokat.go.link/main?showcaseType=MINIMARKET&adj_t=10oehtvh&adj_campaign=opora_samokat&adj_adgroup=skidka_posle_igry&adj_fallback=https%3A%2F%2Fweb.samokat.ru%3Futm_source%3Dpromoaction_marketing%26utm_medium%3Dopora_smkt%26utm_campaign%3Dskidka_posle_igry_sep23&adj_redirect_macos=https%3A%2F%2Fweb.samokat.ru%3Futm_source%3Dpromoaction_marketing%26utm_medium%3Dopora_smkt%26utm_campaign%3Dskidka_posle_igry_sep23',
	app: 'https://vml8.adj.st/?adj_t=10oehtvh&adj_campaign=opora_samokat&adj_adgroup=to_install',
};

export const vangaTales = {
	point1:
		'Этой осенью предлагаем вам поискать свою опору в спокойных и уютных занятиях. Например, в приготовлении домашних закруток. Можно собраться с друзьями и сделать несколько баночек джема из хурмы к блинам на завтрак. \n' +
		'\n' +
		'Больше об этом приятном хобби рассказываем в нашем проекте «Время закруток» с 8 сентября по 1 октября. \n',
	point2:
		'' +
		'Этой осенью предлагаем вам поискать свою опору в заботе о других: благотворительности или волонтёрстве.\n' +
		'\n' +
		'Например, вы можете поддержать людей с ментальными особенностями в нашем проекте с мастерскими «Простые вещи» с 27 сентября по 18 октября. Ребята вдохновились натуральными продуктами от Самоката и сделали красивую авторскую посуду, которую мы разыграем. Следите за новостями!\n',
	point3:
		'Этой осенью предлагаем вам поискать свою опору в новых знаниях. Чтобы не скучать в дождливую погоду, соберите список интересных документалок, запаситесь\n' +
		'нон-фикшн книгами и найдите познавательные каналы на Ютубе. \n' +
		'\n' +
		'А ещё можно сходить на игру «Квиз, плиз» с 6 сентября по 7 октября и ответить на любопытный вопрос от Самоката. \n',
	point4:
		'Этой осенью предлагаем вам поискать свою опору в чём-то логичном и закономерном: например, в интересных математических задачках. Они помогут полезно провести время, потренировать память и вспомнить уроки алгебры. \n' +
		'\n' +
		'Такие примеры есть в нашем проекте, который мы готовим вместе с сервисом «Другое дело» с 20 октября по 3 ноября. В нём много необычных заданий: например, нужно найти косинус угла вафли с ореховой начинкой.\n',
	point5:
		'Этой осенью предлагаем вам поискать свою опору в домашних атмосферных встречах.\n' +
		'\n' +
		'Например, можете собрать друзей на киновечер. А чтобы просмотр был ещё приятнее, закажите сладкий перекус — мягкий мармелад от Самоката. Он очень сочный, здорово поднимает настроение и бывает в виде мишек, машинок и червячков. \n',
	point6:
		'Этой осенью предлагаем вам поискать свою опору в исследовании своего города. \n' +
		'\n' +
		'Например, можно присоединиться к нашему совместному проекту с порталом «Москва глазами инженера» и с 22 сентября по 22 октября пройтись с экскурсией по необычному маршруту. \n',
};
