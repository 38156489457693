<script lang='ts'>
	import { customizationSteps } from '../../stores';
	import MainButton from '../MainButton.svelte';

	let disabled = null;

	export let next = customizationSteps.next;
</script>

<div class='customization-step'>
	<div class='content'>
		<slot />
	</div>
	<MainButton click='{()=>{next();disabled=true;}}' {disabled}>Далее</MainButton>
</div>

<style lang='scss'>
  .customization-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    > .content {
      width: 100%;
      pointer-events: auto;

      :global(> .title) {
        margin-top: 10px;
        padding-left: 70px;
        padding-right: 70px;
        font-size: 24px;
        line-height: 140%;
        font-weight: 700;
        letter-spacing: 0;
        text-align: center;
      }

      :global(> .slider) {
        position: absolute;
        top: 115px;
        left: 0;
        right: 0;
      }

      :global(.slide) {
        height: 320px;
        display: flex;
        place-items: center;
      }
    }

    :global(.button) {
      margin-top: auto !important;

      @include breakpoint-up($tablet) {
        margin-top: 500px !important;
      }
    }
  }
</style>
