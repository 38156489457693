<script lang='ts'>
	import { menu, score, sound } from '../stores';
	import Groznov from './Groznov.svelte';
	import ActionCaption from './ActionCaption.svelte';
	import PrizeButton from './PrizeButton.svelte';
	import PrizesImg from './PrizesImg.svelte';
	import { isPromocodeReceived } from '../utils.js';
</script>

<div class='menu'>
	<div class='container'>
		<div class='header'>
			<button class='resume' on:click={menu.toggle}>
				<svg fill='none' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M12 28.543c0 1.538 1.664 2.5 2.997 1.734l13.988-8.043c1.337-.77 1.337-2.699 0-3.468l-13.988-8.043C13.664 9.957 12 10.92 12 12.457v16.086Z'
						fill='#fff' stroke='#fff' stroke-linecap='round'
						stroke-linejoin='round' />
				</svg>
				Играть
			</button>
			<button class='sound' on:click={sound.toggle}>
				{#if $sound}
					<svg fill='none' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
						<path d='M30 12s6 1.449 6 8-6 8-6 8' stroke='#fff' stroke-linecap='round' stroke-width='2' />
						<path
							d='M8.458 13.571a2 2 0 0 0-2 2v8.858a2 2 0 0 0 2 2h4.548a2 2 0 0 1 1.307.486l7.797 6.73c1.296 1.119 3.307.198 3.307-1.514V7.87c0-1.712-2.011-2.633-3.307-1.514l-7.797 6.73a2 2 0 0 1-1.307.486H8.458Z'
							fill='#fff' stroke='#fff' stroke-linecap='round'
							stroke-linejoin='round' />
					</svg>
				{:else}
					<svg fill='none' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
						<g clip-path='url(#clip0_960_1476)' stroke='#fff' stroke-linecap='round'>
							<path d='m30 24 9-8M30 16l9 8' stroke-width='2' />
							<path
								d='M8.458 13.571a2 2 0 0 0-2 2v8.858a2 2 0 0 0 2 2h4.548a2 2 0 0 1 1.307.486l7.797 6.73c1.296 1.119 3.307.198 3.307-1.514V7.87c0-1.712-2.011-2.633-3.307-1.514l-7.797 6.73a2 2 0 0 1-1.307.486H8.458Z'
								fill='#fff'
								stroke-linejoin='round' />
						</g>
						<defs>
							<clipPath id='clip0_960_1476'>
								<path d='M5 2h35v36H5z' fill='#fff' />
							</clipPath>
						</defs>
					</svg>
				{/if}
			</button>
		</div>
		<div class='result'>
			Ваш результат: <br>
			<span>{$score} очков</span>
		</div>
		<div class='caption'>
			У вас отлично получается! Чтобы немного отдохнуть, можете сделать перерыв на кофе с миндальным круассаном от
			Самоката и продолжить играть.
		</div>
		<div class='prizes-info'>
			<PrizesImg />
		</div>
		{#if isPromocodeReceived()}
			<PrizeButton />
		{/if}
		<ActionCaption />
		<Groznov />
	</div>
</div>

<style lang='scss'>
  .menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(64, 64, 64, 0.7);
    pointer-events: auto;
    overflow-y: auto;

    > .container {
      min-height: 100%;
      padding: 32px;
      background: rgba(64, 64, 66, 1);
      box-sizing: border-box;

      @include breakpoint-up($tablet) {
        width: 390px;
      }

      > .header {
        height: 40px;
        margin-bottom: 68px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .resume {
          @include reset-button;

          display: flex;
          align-items: center;
          font-size: 24px;
          line-height: 32px;

          svg {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
        }

        > .sound {
          @include reset-button;

          @include breakpoint-up($tablet) {
            display: none;
          }

          svg {
            width: 40px;
            height: 40px;
          }
        }
      }

      > .result {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;

        > span {
          font-weight: 700;
        }
      }

      > .caption {
        margin-top: 32px;
        font-size: 18px;
        line-height: 24px;
      }

      > .prizes-info {
        margin-top: 32px;

        > .title {
          font-size: 24px;
          line-height: 32px;
        }

        > .caption {
          font-size: 16px;
          line-height: 20px;
        }
      }

      > .prize-caption {
        margin-top: 40px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
</style>
