<button class='round-button' on:click>
	<slot />
</button>

<style lang='scss'>
  .round-button {
    @include reset-button;

    width: 64px;
    height: 64px;
    background: #FDF2D3;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 64px;
    pointer-events: auto;
  }
</style>
