import { get, writable } from 'svelte/store';
import Start from './lib/screens/Start.svelte';
import Step1 from './lib/customization-steps/Step1.svelte';
import Step2 from './lib/customization-steps/Step2.svelte';
import Step3 from './lib/customization-steps/Step3.svelte';
import Step4 from './lib/customization-steps/Step4.svelte';
import Play from './lib/screens/Play.svelte';
import PlayerCustomization from './lib/screens/PlayerCustomization.svelte';
import type { BagCustomization, CustomizationStepsStore, HintData } from './types';
import game from './game/game';
import TrainingFinish from './lib/screens/TrainingFinish.svelte';
import config from './game/config';
import PhoneInput from './lib/screens/PhoneInput.svelte';
import Results from './lib/screens/Results.svelte';
import Intro from './lib/screens/Intro.svelte';
import TryMore from './lib/screens/TryMore.svelte';
import { Api, getCookie, sawResults, setPromocodeReceived, setSawResults } from './utils';
import { SCENES } from './game/enum';

export const currentScreen = (() => {
	const { subscribe, set } = writable(Start);

	return {
		subscribe,
		play: () => {
			currentObjectCount.set(0);
			lastObjectName.set(null);
			set(Play);
		},
		playChallenge: async (restart = false) => {
			await Api.startGame();
			score.reset();
			bonusInfo.set(!sawResults());
			if (restart) {
				score.reset(0);
				startLives.set(3);
			}
			game.playChallenge();
			set(Play);
		},
		intro: () => set(Intro),
		trainingComplete: () => {
			set(TrainingFinish);
			setPromocodeReceived();
		},
		tryMore: () => set(TryMore),
		challengeOver: async () => {
			await Api.finishGame();
			get(phone) ? set(Results) : set(PhoneInput);
			setSawResults();
		},
		phone: () => set(PhoneInput),
		results: () => set(Results),
		customization: () => set(PlayerCustomization),
	};
})();

export const currentPopup = writable();

export const score = (() => {
	const { subscribe, set, update } = writable(0);

	return {
		subscribe,
		reset: (val = get(lastGameScore)) => set(val),
		add: () =>
			update(n => {
				return n + 10;
			}),
		boost: () => {
			update(n => {
				return n + config.pointsBoost;
			});
		},
	};
})();

export const currentLives = writable(3);
export const startLives = writable(3);

export const bag = (() => {
	const { subscribe, set } = writable<BagCustomization>({
		form: 0,
		face: 0,
		name: '',
		totem: 0,
	});

	return {
		subscribe,
		set,
	};
})();

export const progress = (() => {
	const { subscribe, update } = writable({});

	return {
		subscribe,
		add: (name: string) => {
			update(n => {
				if (!n[name]) n[name] = 0;

				n[name]++;

				return n;
			});
		},
	};
})();

export const customizationSteps = (() => {
	const { subscribe, update } = writable<CustomizationStepsStore>({
		step: 1,
		component: Step1,
	});

	const getComponent = (step: number) => {
		return (() => {
			switch (step) {
				case 1:
					return Step1;

				case 2:
					return Step2;

				case 3:
					return Step3;

				case 4:
					return Step4;

				default:
					return Step1;
			}
		})();
	};

	return {
		subscribe,
		step: step => {
			update(() => ({
				step,
				component: getComponent(step),
			}));
		},
		next: () => {
			update(n => ({
				step: n.step + 1,
				component: getComponent(n.step + 1),
			}));
		},
		prev: () => {
			update(n => ({
				step: n.step - 1,
				component: getComponent(n.step - 1),
			}));
		},
	};
})();

export const phone = writable();

export const hints = (() => {
	const { subscribe, update, set } = writable<HintData[]>([]);

	let timeout;

	return {
		subscribe,
		add: (data: HintData) => {
			update(n => [...n, data]);

			clearTimeout(timeout);

			timeout = setTimeout(() => {
				set([]);
			}, config.hintsTimeout);
		},
	};
})();

export const menu = (() => {
	const { subscribe, update, set } = writable(false);

	return {
		subscribe,
		open: () => {
			set(true);
			game.togglePause(true);
		},
		toggle: () => {
			update(val => !val);
			game.togglePause(get(menu));
		},
	};
})();

export const sound = (() => {
	const { subscribe, update } = writable(true);

	return {
		subscribe,
		toggle: () => {
			update(val => !val);
			game.toggleSound();
		},
	};
})();

export const onboarding = writable(true);
export const bonusInfo = writable(false);

export const attemptId = writable();

export const myBestResults = writable();

export const auth = writable(false);

export const lastGameScore = writable(0);

export const policyAccepted = writable(getCookie('policy') || false);

export const wrongOrientationScreen = writable(false);

export const currentObjectCount = writable(0);
export const lastObjectName = writable();

export const gameType = writable(SCENES.TRAINING);
