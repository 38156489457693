<script>
	import Image from './Image.svelte';
</script>

<div class='bg'>
	<Image src='moon.png' />
</div>

<style lang='scss'>
  .bg {
    height: 290px;
    position: absolute;;
    top: 135px;
    transform: translateX(80px);
    z-index: -1;

    :global(img) {
      width: auto;
      height: 100%;
    }
  }
</style>
