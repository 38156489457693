<script lang='ts'>
	import Template from './+template.svelte';
	import Slider from './Slider.svelte';
	import { bag, currentScreen, customizationSteps, onboarding } from '../../stores';
	import { onMount } from 'svelte';
	import { totems } from '../../texts';
	import Text from './Text.svelte';
	import type { BagCustomization } from '../../types';
	import BackButton from '../BackButton.svelte';
	import { Api, isPromocodeReceived, isUserRegistered } from '../../utils';
	import Image from '../Image.svelte';

	let title;
	let subtitle;

	const next = async () => {
		if (isUserRegistered()) {
			await Api.update();
		} else {
			await Api.register();
		}

		if (isUserRegistered() && isPromocodeReceived()) {
			onboarding.set(false);
			await currentScreen.playChallenge();
		} else {
			currentScreen.play();
		}
	};

	const pageChange = (e) => setTotem(e.detail);

	const setTotem = (index) => {
		title = totems[index][0];
		subtitle = totems[index][1];
		(<BagCustomization>$bag).totem = index;
	};

	onMount(() => {
		setTotem((<BagCustomization>$bag).totem);
	});
</script>

<Template {next}>
	<BackButton on:click={customizationSteps.prev} />

	<div class='title'>Выберите талисман</div>
	<div class='slider'>
		<Slider initialPageIndex={$bag.totem} on:pageChange={pageChange}>
			<div class='slide'>
				<Image src='live1.png' />
			</div>

			<div class='slide'>
				<Image src='live2.png' />
			</div>

			<div class='slide'>
				<Image src='live3.png' />
			</div>

			<div class='slide'>
				<Image src='live4.png' />
			</div>

			<div class='slide'>
				<Image src='live5.png' />
			</div>

			<div class='slide'>
				<Image src='live6.png' />
			</div>
		</Slider>
	</div>
	<Text {subtitle} {title} />
</Template>

<style lang='scss'>
  .slide {
    > :global(picture) {
      width: 180px;
      height: 180px;
      margin: auto;
    }
  }
</style>
