<script lang='ts'>
	import RoundButton from './RoundButton.svelte';
	import { sound } from '../stores';

	const toggleSound = () => sound.toggle();
</script>

<RoundButton on:click={toggleSound}>
	{#if $sound}
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 40 40'>
			<path stroke='#2B4644' stroke-linecap='round' stroke-width='2' d='M30 12s6 1.449 6 8-6 8-6 8' />
			<path fill='#2B4644' stroke='#2B4644' stroke-linecap='round' stroke-linejoin='round'
						d='M8.458 13.571a2 2 0 0 0-2 2v8.858a2 2 0 0 0 2 2h4.548a2 2 0 0 1 1.307.486l7.797 6.73c1.296 1.119 3.307.198 3.307-1.514V7.87c0-1.712-2.011-2.633-3.307-1.514l-7.797 6.73a2 2 0 0 1-1.307.486H8.458Z' />
		</svg>
	{:else}
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 40 40'>
			<g stroke='#2B4644' stroke-linecap='round' clip-path='url(#clip0_656_2189)'>
				<path stroke-width='2' d='m30 24 9-8M30 16l9 8' />
				<path fill='#2B4644' stroke-linejoin='round'
							d='M8.458 13.571a2 2 0 0 0-2 2v8.858a2 2 0 0 0 2 2h4.548a2 2 0 0 1 1.307.486l7.797 6.73c1.296 1.119 3.307.198 3.307-1.514V7.87c0-1.712-2.011-2.633-3.307-1.514l-7.797 6.73a2 2 0 0 1-1.307.486H8.458Z' />
			</g>
			<defs>
				<clipPath id='clip0_656_2189'>
					<path fill='#fff' d='M5 2h35v36H5z' />
				</clipPath>
			</defs>
		</svg>
	{/if}
</RoundButton>

<style lang='scss'>
  svg {
    width: 40px;
    height: 40px;
  }
</style>
