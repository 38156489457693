<script>
	import Button from './Button.svelte';
	import { res } from '../game/res';

	export let disabled = null;
	export let click;

	const onClick = () => {
		res.sounds.button.play();
		click && click();
	};
</script>

<Button bind:disabled={disabled} on:click={onClick}>
	<slot />
</Button>
