import type { ActorArgs } from 'excalibur';
import { Actor, Vector } from 'excalibur';

export default abstract class Item extends Actor {
	constructor(props: ActorArgs) {
		super({
			...props,
		});
	}

	onInitialize() {
		this.addGraphics();
	}

	async use() {
		await this.actions.scaleTo(Vector.Zero, new Vector(10, 10)).toPromise();
		this.kill();
	}

	protected abstract addGraphics();
}
