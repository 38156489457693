<script lang='ts'>
	import Template from './+template.svelte';
	import Slider from './Slider.svelte';
	import type { BagCustomization } from '../../types';
	import { bag, customizationSteps } from '../../stores';
	import { onMount } from 'svelte';
	import BackButton from '../BackButton.svelte';
	import Image from '../Image.svelte';
	import { isUserRegistered } from '../../utils';

	const setFace = (index) => {
		(<BagCustomization>$bag).face = index;
	};

	const pageChange = (e) => setFace(e.detail);

	onMount(() => {
		setFace((<BagCustomization>$bag).face);
	});

	const next = () => {
		if (isUserRegistered()) {
			customizationSteps.step(4);
		} else {
			customizationSteps.next();
		}
	};
</script>


<Template {next}>
	<BackButton on:click={customizationSteps.prev} />

	<div class='title'>Выберите характер</div>
	<div class='slider'>
		<div class='bg'>
			<Image src='bag/Form_0{$bag.form+1}.png' />
		</div>
		<Slider initialPageIndex={$bag.face} on:pageChange={pageChange}>
			<div class='slide'>
				<Image src='face/Emotion_01.png' />
			</div>
			<div class='slide'>
				<Image src='face/Emotion_02.png' />
			</div>
			<div class='slide'>
				<Image src='face/Emotion_03.png' />
			</div>
			<div class='slide'>
				<Image src='face/Emotion_04.png' />
			</div>
			<div class='slide'>
				<Image src='face/Emotion_05.png' />
			</div>
			<div class='slide'>
				<Image src='face/Emotion_06.png' />
			</div>
			<div class='slide'>
				<Image src='face/Emotion_07.png' />
			</div>
		</Slider>
	</div>
</Template>

<style lang='scss'>
  .slider {
    .bg {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;

      :global(img) {
        width: auto;
        height: 320px;
        aspect-ratio: 608 / 640;
      }
    }
  }

  .slide {
    > :global(picture) {
      width: auto;
      height: 320px;
      margin: auto;
      aspect-ratio: 608 / 640;
    }
  }
</style>
