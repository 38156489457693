<script lang='ts'>
	import Score from '../Score.svelte';
	import Header from '../Header.svelte';
	import OnBoarding from './OnBoarding.svelte';
	import { bonusInfo, onboarding } from '../../stores';
	import BonusPopup from '../BonusPopup.svelte';
	import ObjectsCounter from '../ObjectsCounter.svelte';
	import { gameType } from '../../stores.js';
	import { SCENES } from '../../game/enum.js';
</script>

<div class='play-scene-ui'>
	<Header />
	<Score />

	{#if $onboarding}
		<OnBoarding />
	{/if}

	{#if $bonusInfo}
		<BonusPopup />
	{/if}

	{#if $gameType === SCENES.TRAINING && !$onboarding}
		<ObjectsCounter />
	{/if}
</div>

<style lang='scss'>
  .play-scene-ui {
    padding: 20px;
    box-sizing: border-box;
  }
</style>
