import { currentScreen, gameType, score } from '../../stores';
import GameScene from '../partials/game-scene';
import game from '../game';
import { res } from '../res';
import { random } from '../utils';
import type { SceneActivationContext } from 'excalibur';
import { Vector } from 'excalibur';
import config from '../config';
import type { SceneData } from '../../types';
import Point from '../components/point';
import { get } from 'svelte/store';
import LifeBoost from '../components/life-boost';
import PointsBoost from '../components/points-boost';
import { SCENES } from '../enum';

export default class Challenge extends GameScene {
	static lifeBoostTime = 4 * 60 * 1000;
	static pointsBoostTime = 60 * 1000;
	protected lifeBoostTimer!: DOMHighResTimeStamp;
	protected pointsBoostTimer!: DOMHighResTimeStamp;

	onActivate(ctx: SceneActivationContext<SceneData>) {
		gameType.set(SCENES.CHALLENGE);
		this.lifeBoostTimer = performance.now();
		this.pointsBoostTimer = performance.now();

		super.onActivate(ctx);
	}

	addScore() {
		super.addScore();

		this.player.speed =
			Math.floor(get(score) / 1000) * config.scrollSpeedMultiply * config.scrollSpeed +
			config.scrollSpeed;

		this.player.inputAvailable && this.player.updateVel();

		if (get(score) === 1000) {
			res.sounds.music.stop();
			res.sounds.music2.loop = true;
			res.sounds.music2.play();
		}
	}

	protected async gameOver(): Promise<void> {
		for (let timer of this.timers) timer.stop();

		this.player.stop();
		game.stopMusic();
		res.sounds.gameOver.play();
		await game.waitFor(2000);
		currentScreen.challengeOver();
		await game.waitFor(1000);
		this.onDeactivate();
	}

	protected addPoints(globalOffset: number = 0) {
		for (let i = 0; i < config.scenePreloadItems; i++) {
			const itemsCount = random.integer(1, 3);
			const pos = random.pickSet(this.itemsPositions, itemsCount);

			let excludes = [];

			for (let p of pos) {
				const itemPos = new Vector(
					(i + 1) * config.statueOffset + config.statueOffset / 2 + globalOffset,
					0,
				)
					.add(p)
					.add(new Vector(random.integer(-100, 50), random.integer(-100, 100)));

				let point: Point | LifeBoost | PointsBoost = new Point(
					{
						pos: itemPos,
					},
					excludes,
				);

				if (performance.now() - this.pointsBoostTimer >= Challenge.pointsBoostTime) {
					point = new PointsBoost({ pos: itemPos });

					this.pointsBoostTimer = performance.now();
				}

				if (performance.now() - this.lifeBoostTimer >= Challenge.lifeBoostTime) {
					point = new LifeBoost({ pos: itemPos });

					this.lifeBoostTimer = performance.now();
				}

				point.once('enterviewport', () => {
					point.on('exitviewport', () => {
						if (point.pos.x < this.player.pos.x) point.kill();
					});
				});

				point instanceof Point && excludes.push(point.currentGraphicsName);

				this.add(point);
			}
		}
	}
}
