<script lang='ts'>
	import Template from './+template.svelte';
	import ActionCaption from '../ActionCaption.svelte';
	import { bag, currentScreen, progress } from '../../stores';
	import MainButton from '../MainButton.svelte';
	import { links, vangaTales } from '../../texts';
	import { onMount } from 'svelte';
	import PrizeButton from '../PrizeButton.svelte';
	import PrizesImg from '../PrizesImg.svelte';
	import Image from '../Image.svelte';
	import Groznov from '../Groznov.svelte';

	let resultBg;

	onMount(() => {
	});

	const points = Object.keys($progress).sort((a, b) => {
		return $progress[a] < $progress[b] ? 1 : -1;
	});

	const playChallenge = () => {
		currentScreen.playChallenge();

		window.exponea.track('opora_smkt', {
			event_action: 'game_record_play',
		});
	};

	const calculateAngle = (index) => {
		const startOffset = -30;

		return `${startOffset + (360 - startOffset) / points.length * index}deg`;
	};

	const calculateRadius = (index) => {
		const startOffset = 10;
		const endOffset = 14;

		return `calc(${startOffset}% + (50% - ${endOffset + startOffset}%) / ${points.length - 1} * ${index})`;
	};
</script>

<Template class='-bg'>
	<div class='title'>
		Классная игра!
	</div>
	<div class='intro'>
		<div class='name'>{$bag.name},</div>
	</div>
	<div bind:this='{resultBg}' class='best-result -{points[0]}'>
		<div class='bg'>
			<Image src='result-diagram-bg.png' />
		</div>
		{#each points as point, index}
			<Image
				style='--angle: {calculateAngle(index)}; --rad: {calculateRadius(index)}; transform: translate(-50%, -50%) scale(calc(1 - {index} * 0.7 / {points.length}))'
				src='{point}.png' />
		{/each}
	</div>
	<div class='text'>
		{vangaTales[points[0]]}
	</div>
	<div class='text'>
		<strong>Теперь вы знаете, что может поддержать вас этой осенью. Переходите в приложение и забирайте подарок
			— скидку 7% при заказе от 700 ₽. Она начислится автоматически.</strong>
	</div>
	<PrizeButton />
	<div class='text'>
		Если у вас нет нашего приложения — скачайте его <a href='{links.app}' target='_blank'>здесь</a>.
		<br>
		<br>
		Чтобы поставить рекорд и поучаствовать в розыгрыше классной техники — продолжайте играть, а скидку сможете забрать
		позже. Она будет ждать вас в окошке сверху.
	</div>
	<PrizesImg />
	<div class='button-wrapper'>
		<MainButton click='{playChallenge}'>Играть на рекорд</MainButton>
	</div>
	<ActionCaption />
	<Groznov />
</Template>

<style lang='scss'>
  .title {
    font-size: 32px;
    font-weight: 500;
    line-height: 41px;
  }

  .intro {
    margin-top: 16px;
    font-size: 20px;
    line-height: 25px;

    .name {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .text + :global(.button) {
    margin-top: 24px !important;
  }

  .best-result {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    display: flex;

    > .bg {
      position: absolute;
      display: flex;

      &:before {
        width: 59%;
        height: 59%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: var(--bg-glow);
        filter: blur(37px);
        content: '';
      }

      :global(picture) {
        width: 100%;
        height: 100%;
        aspect-ratio: 1166 / 1166;
        object-fit: contain;
      }
    }

    > :global(picture) {
      --angle: 0deg;
      --rad: 0px;

      width: 70px;
      height: 70px;
      position: absolute;
      top: calc(50% + var(--rad) * cos(var(--angle)));
      left: calc(50% + var(--rad) * sin(var(--angle)));
      transform-origin: center;
      transform: translate(-50%, -50%);
      object-fit: contain;

      &:first-of-type {
        top: 50% !important;
        left: 50% !important;
        z-index: 1;
      }
    }

    &.-point1 {
      --bg-glow: #F8E4A2;
    }

    &.-point2 {
      --bg-glow: #9DBCED;
    }

    &.-point3 {
      --bg-glow: #2E5F5C;
    }

    &.-point4 {
      --bg-glow: #FFC653;
    }

    &.-point5 {
      --bg-glow: #FE4321;
    }

    &.-point6 {
      --bg-glow: #8B94A7;
    }
  }

  .button-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;

    @include breakpoint-up($tablet) {
      width: 326px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .text {
    margin-top: 24px;
    font-size: 20px;
    line-height: 25px;

    a {
      @include reset-link;
      text-decoration: underline;
    }
  }
</style>
