<script lang='ts'>
	export let disabled = false;
	export let href = '';
	let className: string = '';

	let el = href ? 'a' : 'button';

	export { className as class };
</script>

<svelte:element class='button {className}' disabled='{disabled}'
								href={href || null}
								on:click
								target='{href ? "_blank" : null}' this='{el}'>
	<slot />
</svelte:element>

<style lang='scss'>
  .button {
    @include reset-button;
    @include reset-link;

    width: 100%;
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 72, 111, 1);
    border-radius: 50px;
    pointer-events: auto;
    font-size: 24px;
    line-height: 32px;

    @include breakpoint-up($tablet) {
      max-width: 326px;
      margin-left: auto;
      margin-right: auto;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
</style>
