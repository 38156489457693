import type { ActorArgs } from 'excalibur';
import { Actor, vec } from 'excalibur';
import { res } from '../res';

export default class ScoreInformer extends Actor {
	constructor(props: ActorArgs, type: 'live-boost' | 'score-boost' | 'score' = 'score') {
		super(props);

		this.graphics.use(res.scene.getFrameSprite(`scene/${type}`));
	}

	async onInitialize() {
		await this.actions.easeBy(vec(0, -100), 350).toPromise();

		this.kill();
	}
}
