<script lang='ts'>
	import RoundButton from './RoundButton.svelte';
	import { menu } from '../stores';

	const menuToggle = () => menu.toggle();
</script>

<RoundButton on:click={menuToggle}>
	<svg fill='none' viewBox='0 0 21 28' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_608_3980)' stroke='#000' stroke-linecap='square' stroke-linejoin='round'
			 stroke-width='7'>
			<path d='M16.37 3.5v21M4.63 3.5v21' />
		</g>
		<defs>
			<clipPath id='clip0_608_3980'>
				<path d='M20.563 0v28H.438V0z' fill='#fff' />
			</clipPath>
		</defs>
	</svg>
</RoundButton>

<style lang='scss'>
  svg {
    width: 21px;
    height: 28px;
  }
</style>
