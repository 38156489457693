import { ImageSource, Sound } from 'excalibur';
import JSONSpriteSheet from './partials/json-spritesheet';

export const res = {
	player: new ImageSource('/assets/player.png'),
	scene: new JSONSpriteSheet('/assets/scene.json'),
	sounds: {
		boost: new Sound('/assets/sound/boost.mp3'),
		button: new Sound('/assets/sound/button.mp3'),
		custom: new Sound('/assets/sound/custom.mp3'),
		fall: new Sound('/assets/sound/fall.mp3'),
		gameOver: new Sound('/assets/sound/game-over.mp3'),
		catch: new Sound('/assets/sound/catch.mp3'),
		life: new Sound('/assets/sound/life.mp3'),
		music: new Sound('/assets/sound/music.mp3'),
		music2: new Sound('/assets/sound/music2.mp3'),
	},
};
