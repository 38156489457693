import game from '../game';
import type { BagCustomization, SceneData } from '../../types';
import type { SceneActivationContext } from 'excalibur';
import {
	Actor,
	Axis,
	ParallaxComponent,
	Scene,
	ScreenElement,
	Timer,
	Trigger,
	Vector,
} from 'excalibur';
import StatueFactory from './statue-factory';
import { random } from '../utils';
import { LockCameraToActorAxisWithOffsetStrategy } from './lock-camera-with-offset';
import { res } from '../res';
import Player from '../components/player';
import { GAME_EVENTS } from '../enum';
import Statue from '../components/statue';
import Cloud from '../components/cloud';
import config from '../config';
import { currentLives, score, startLives } from '../../stores';
import { get } from 'svelte/store';

export default abstract class GameScene extends Scene {
	public lives: number = 3;
	protected player!: Player;
	protected bonusTimer!: Timer;
	protected posMultiple = 400;
	protected itemsPositions = [
		new Vector(this.posMultiple, 0),
		new Vector(-this.posMultiple, this.posMultiple / 4),
		new Vector(this.posMultiple / 2, -this.posMultiple),
		new Vector(-this.posMultiple / 3, this.posMultiple),
	];
	private autostart!: boolean;

	onInitialize() {
		this.registerEvents();

		this.camera.pos.setTo(0, 0);

		const x = this.camera.viewport.center.x - game.halfDrawWidth;
		const y = this.camera.viewport.center.y - game.halfDrawHeight;

		const bg = new ScreenElement({
			pos: new Vector(x, y),
		});
		const sprite = res.scene.getFrameSprite('scene/gradient');

		bg.graphics.use(sprite);
		sprite.scale.x = game.drawWidth / sprite.width;
		sprite.scale.y = game.drawHeight / sprite.height;

		this.add(bg);
	}

	onActivate(ctx: SceneActivationContext<SceneData>) {
		this.lives = get(startLives);
		this.autostart = ctx.data.autostart;

		this.addBg();
		this.addPlayer(ctx.data.bag);
		this.addGround(-6174);
		this.addStatues();
		this.addForeground();
		this.addPoints(0);
		this.updateLivesUI();

		game.startMusic();

		this.bonusTimer = new Timer({
			fcn: () => this.addScore(),
			interval: 1000,
			repeats: true,
		});

		this.add(this.bonusTimer);

		if (this.autostart) {
			requestAnimationFrame(() => this.start());
		}
	}

	onDeactivate() {
		for (let timer of this.timers) this.remove(timer);
		for (let entity of this.entities)
			if (!(entity instanceof ScreenElement)) entity.kill();
	}

	hurtPlayer() {
		this.lives--;
		this.updateLivesUI();
	}

	togglePlayer(val: boolean) {
		if (!val) {
			this.player.start();
			for (let timer of this.timers) timer.resume();
		} else {
			this.player.stop();
			for (let timer of this.timers) timer.pause();
		}
	}

	addLives() {
		this.lives++;
		this.updateLivesUI();
	}

	start() {
		this.bonusTimer.start();

		if (this.player.isInitialized) this.player.start();
		else
			this.player.once('initialize', () => {
				this.player.start();
			});
	}

	protected abstract gameOver(): Promise<void>;

	protected addBg() {
		const moon = new Actor({
			pos: new Vector(400, 0),
			anchor: Vector.Half,
		});

		moon.addComponent(new ParallaxComponent(new Vector(0.05, 0)));
		moon.graphics.use(res.scene.getFrameSprite('scene/moon'));

		this.add(moon);
	}

	protected addGround(globalOffset = 0) {
		const bg1Sprite = res.scene.getFrameSprite('scene/bg1');
		const bg2Sprite = res.scene.getFrameSprite('scene/bg2');
		const bg3Sprite = res.scene.getFrameSprite('scene/bg3');
		const bgWidth = bg1Sprite.width + bg2Sprite.width + bg3Sprite.width;

		let fields = [];

		for (let i = 0; i < config.scenePreloadItems; i++) {
			const field = new Actor({
				name: 'field',
				width: bgWidth,
				pos: new Vector(globalOffset + i * bgWidth, game.halfDrawHeight),
				anchor: Vector.Down,
				z: 0,
			});

			field.addComponent(new ParallaxComponent(new Vector(0.9, 0)));

			field.graphics.layers
				.create({
					name: `left`,
					order: 0,
					offset: new Vector(0, 0),
				})
				.use(bg1Sprite);

			field.graphics.layers
				.create({
					name: `center`,
					order: 0,
					offset: new Vector(bg1Sprite.width, 0),
				})
				.use(bg2Sprite);

			field.graphics.layers
				.create({
					name: `right`,
					order: 0,
					offset: new Vector(bg1Sprite.width + bg2Sprite.width, 0),
				})
				.use(bg3Sprite);

			fields.push(field);
			this.add(field);
		}

		fields[config.scenePreloadItems - 1].once('enterviewport', () => {
			this.addGround(globalOffset + config.scenePreloadItems * bgWidth);

			// for (let entity of this.world.entityManager.getByName('field')) {
			// 	let actor = <Actor>entity;
			//
			// 	if (this.player.pos.x > actor.pos.x + bgWidth && actor.isOffScreen) {
			// 		actor.kill();
			// 	}
			// }
		});
	}

	protected addForeground(globalOffset = 0) {
		for (let i = 0; i < config.scenePreloadItems; i++) {
			const sprite = random.pickOne([
				res.scene.getFrameSprite('scene/fp1'),
				res.scene.getFrameSprite('scene/fp2'),
				res.scene.getFrameSprite('scene/fp3'),
				res.scene.getFrameSprite('scene/fp4'),
				res.scene.getFrameSprite('scene/fp5'),
				res.scene.getFrameSprite('scene/fp6'),
			]);

			const fp = new Actor({
				name: 'fp',
				pos: new Vector(config.statueOffset * i + globalOffset, game.halfDrawHeight),
				anchor: new Vector(0.5, 1),
				z: 3,
			});

			fp.addComponent(new ParallaxComponent(new Vector(1.1, 0)));
			fp.graphics.use(sprite);

			fp.once('enterviewport', () => {
				if (i + 1 === config.scenePreloadItems)
					this.addForeground(
						globalOffset + config.scenePreloadItems * config.statueOffset,
					);

				fp.on('exitviewport', () => {
					if (fp.pos.x < this.player.pos.x) {
						fp.kill();
					}
				});
			});

			this.add(fp);
		}
	}

	protected addPlayer(bag: BagCustomization) {
		this.player = new Player({
			x: 0,
			y: 0,
			bag,
		});

		this.camera.addStrategy(
			new LockCameraToActorAxisWithOffsetStrategy(
				this.player,
				Axis.X,
				-game.halfDrawWidth / 2,
			),
		);

		this.add(this.player);
	}

	protected abstract addPoints(globalOffset: number);

	protected addStatues(globalOffset = 0) {
		const statues = StatueFactory.create(config.scenePreloadItems, globalOffset);

		for (const statue of statues) {
			for (let [index, config] of statue.entries()) {
				let en: Actor;

				if (index === 0) {
					en = new Statue(config);
				}

				if (index === 1) {
					en = new Cloud(config);
				}

				en.once('enterviewport', () => {
					en.on('exitviewport', () => {
						if (en.pos.x < this.player.pos.x) {
							en.kill();
						}
					});
				});

				this.add(en);
			}
		}

		let lastStatue = statues.pop()[0];

		const trigger = new Trigger({
			width: 100,
			height: game.drawHeight * 1.5,
			pos: new Vector(lastStatue.pos.x - game.drawWidth, 0),
			action: () => {
				this.addStatues(lastStatue.pos.x);
				this.addPoints(lastStatue.pos.x);
				trigger.kill();
			},
			target: this.player,
		});

		this.add(trigger);
	}

	protected registerEvents() {
		this.events.on(GAME_EVENTS.RESTART, () => this.restart());
		this.events.on(GAME_EVENTS.GAME_OVER, () => this.gameOver());
		this.events.on(GAME_EVENTS.SCORE, () => this.addScore());
	}

	protected addScore() {
		score.add();
	}

	protected restart() {
		const currentStatuesXPosition =
			Math.round(this.player.pos.x / config.statueOffset) * config.statueOffset;

		this.player.reset(currentStatuesXPosition - config.statueOffset / 2, !game.isPaused);
	}

	private updateLivesUI() {
		currentLives.set(this.lives);
	}
}
