<script>
	import Image from './Image.svelte';
</script>

<div class='prizes'>
	<Image src='prizes.png' />
</div>

<style lang='scss'>
  .prizes {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    :global(img) {
      max-width: 510px;
      width: 100%;
      height: auto;
      aspect-ratio: 1020 / 540;
    }
  }
</style>
