<script>
	import SoundButton from './SoundButton.svelte';
	import PauseButton from './PauseButton.svelte';
	import Lives from './Lives.svelte';
</script>

<div class='header'>
	<div class='buttons'>
		<PauseButton />
		<Lives />
		<SoundButton />
	</div>
</div>

<style lang='scss'>
  .buttons {
    display: flex;
    justify-content: space-between;
  }
</style>
