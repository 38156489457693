export const columns = [
	{
		height: 0,
		id: 2,
		name: '',
		polygon: [
			{
				x: 77,
				y: -4,
			},
			{
				x: 144,
				y: -2,
			},
			{
				x: 235,
				y: 68,
			},
			{
				x: 248,
				y: 171,
			},
			{
				x: 249,
				y: 992,
			},
			{
				x: 42,
				y: 995,
			},
			{
				x: 47,
				y: 288,
			},
			{
				x: 0,
				y: 269,
			},
			{
				x: 4.5,
				y: 102,
			},
		],
		rotation: 0,
		type: '',
		visible: true,
		width: 0,
		x: 0,
		y: 1,
	},
	{
		height: 0,
		id: 1,
		name: '',
		polygon: [
			{
				x: 0,
				y: 0,
			},
			{
				x: 21,
				y: -7,
			},
			{
				x: 198.5,
				y: -7,
			},
			{
				x: 235.5,
				y: 7.5,
			},
			{
				x: 254.5,
				y: 30,
			},
			{
				x: 322.5,
				y: 119.5,
			},
			{
				x: 353.5,
				y: 144.5,
			},
			{
				x: 374,
				y: 223.5,
			},
			{
				x: 376.5,
				y: 1042,
			},
			{
				x: 80.1666666666667,
				y: 1043.66666666667,
			},
			{
				x: 103.166666666667,
				y: 402.666666666667,
			},
			{
				x: 51.8333333333333,
				y: 354,
			},
			{
				x: 53.8333333333333,
				y: 320,
			},
			{
				x: 130.5,
				y: 261.333333333333,
			},
			{
				x: 131.833333333333,
				y: 206.666666666667,
			},
			{
				x: -8.16666666666667,
				y: 30.6666666666667,
			},
		],
		rotation: 0,
		type: '',
		visible: true,
		width: 0,
		x: 5.5,
		y: 6,
	},
	{
		height: 0,
		id: 1,
		name: '',
		polygon: [
			{
				x: 2.81818181818183,
				y: 0,
			},
			{
				x: 64.6363636363636,
				y: -1.31818181818182,
			},
			{
				x: 97.1363636363636,
				y: 47.6818181818182,
			},
			{
				x: 97.6363636363636,
				y: 1094.18181818182,
			},
			{
				x: -111.863636363636,
				y: 1093.68181818182,
			},
			{
				x: -99.8636363636364,
				y: 546.681818181818,
			},
			{
				x: -71.8636363636364,
				y: 509.681818181818,
			},
			{
				x: -65.8636363636364,
				y: 306.181818181818,
			},
			{
				x: -104.863636363636,
				y: 294.681818181818,
			},
			{
				x: -118.363636363636,
				y: 267.681818181818,
			},
			{
				x: -105.863636363636,
				y: 233.681818181818,
			},
			{
				x: -65.8636363636364,
				y: 195.181818181818,
			},
			{
				x: -36.8636363636364,
				y: 35.1818181818182,
			},
		],
		rotation: 0,
		type: '',
		visible: true,
		width: 0,
		x: 118.363636363636,
		y: -0.181818181818182,
	},
];

export const clouds = [
	{
		height: 0,
		id: 1,
		name: '',
		polygon: [
			{
				x: 0,
				y: 0,
			},
			{
				x: 87.5,
				y: -132,
			},
			{
				x: 169,
				y: -143,
			},
			{
				x: 210.5,
				y: -165.5,
			},
			{
				x: 205,
				y: -1360.5,
			},
			{
				x: 4,
				y: -1356.5,
			},
			{
				x: 10.5,
				y: -257.5,
			},
			{
				x: -55,
				y: -249.5,
			},
			{
				x: -73,
				y: -213,
			},
			{
				x: -52.5,
				y: -171.5,
			},
			{
				x: -1,
				y: -114,
			},
			{
				x: -30.5,
				y: -61.5,
			},
		],
		rotation: 0,
		type: '',
		visible: true,
		width: 0,
		x: 71,
		y: 678.5,
	},
	{
		height: 0,
		id: 1,
		name: '',
		polygon: [
			{
				x: 0,
				y: 0,
			},
			{
				x: 85.5,
				y: -115,
			},
			{
				x: 243,
				y: -229,
			},
			{
				x: 242,
				y: -398.5,
			},
			{
				x: -15,
				y: -395,
			},
			{
				x: -86.5,
				y: -357,
			},
			{
				x: -108,
				y: -291.5,
			},
			{
				x: -100.5,
				y: -251.5,
			},
			{
				x: -51.5,
				y: -190.5,
			},
			{
				x: 6.5,
				y: -155,
			},
			{
				x: -16.5,
				y: -93,
			},
		],
		rotation: 0,
		type: '',
		visible: true,
		width: 0,
		x: 108,
		y: 398.5,
	},
];
