import Item from '../partials/item';
import { res } from '../res';
import type { ActorArgs } from 'excalibur';
import { vec } from 'excalibur';
import { hints } from '../../stores';
import { hints as hText } from '../../texts';
import ScoreInformer from './score-informer';

export default class LifeBoost extends Item {
	static hintUsed = false;
	private used = false;

	constructor(props: ActorArgs) {
		super({
			...props,
			width: 80 - 20,
			height: 136 - 20,
			name: 'life-boost',
		});
	}

	async use(): Promise<void> {
		if (this.used) return;
		this.used = true;

		if (!LifeBoost.hintUsed) {
			hints.add({
				img: `${this.name}.png`,
				text: hText[7],
			});

			LifeBoost.hintUsed = true;
		}

		const si = new ScoreInformer(
			{
				pos: this.pos.sub(vec(-this.width / 2, this.height)),
			},
			'live-boost',
		);

		this.scene.add(si);

		res.sounds.life.play();
		super.use();
	}

	protected addGraphics() {
		this.graphics.use(res.scene.getFrameSprite('scene/life-boost'));
	}
}
