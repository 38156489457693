<script lang='ts'>
	let className: string = '';

	export { className as class };
</script>

<section class='screen {className}'>
	<div class='container'>
		<slot />
	</div>
</section>

<style lang='scss'>
  .screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(64, 64, 66, 1);
    overflow-x: hidden;
    pointer-events: auto;

    > .container {
      max-width: 100%;
      min-height: 700px;
      padding: 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      overflow: hidden;

      @include breakpoint-up($tablet) {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.-gradient-bg {
      background: linear-gradient(180deg, #FE5472 0%, #BC3F78 71.35%, #9E3D80 94.79%);

      > .container {
        padding-bottom: 24px;
        align-items: center;
      }
    }
  }
</style>
