import type { SceneActivationContext } from 'excalibur';
import { Vector } from 'excalibur';
import config from '../config';
import game from '../game';
import {
	currentObjectCount,
	currentScreen,
	gameType,
	lastGameScore,
	score,
	startLives,
} from '../../stores';
import type { SceneData } from '../../types';
import GameScene from '../partials/game-scene';
import { res } from '../res';
import { random } from '../utils';
import Point from '../components/point';
import { GAME_EVENTS, SCENES } from '../enum';
import { get } from 'svelte/store';

export default class Training extends GameScene {
	private currentObjectCount!: number;

	onInitialize() {
		this.events.on(GAME_EVENTS.SCORE, () => {
			this.currentObjectCount++;
			currentObjectCount.set(this.currentObjectCount);
			if (this.currentObjectCount === config.trainingObjectCount) this.trainingComplete();
		});

		super.onInitialize();
	}

	onActivate(ctx: SceneActivationContext<SceneData>) {
		gameType.set(SCENES.TRAINING);
		this.currentObjectCount = 0;
		super.onActivate(ctx);
		score.reset();
	}

	protected async gameOver(): Promise<void> {
		for (let timer of this.timers) timer.stop();

		this.player.stop();
		game.stopMusic();
		res.sounds.gameOver.play();
		await game.waitFor(2000);
		currentScreen.tryMore();
		await game.waitFor(1000);
		this.onDeactivate();
	}

	protected addPoints(globalOffset: number = 0) {
		for (let i = 0; i < config.scenePreloadItems; i++) {
			const itemsCount = random.integer(1, 3);
			const pos = random.pickSet(this.itemsPositions, itemsCount);

			let excludes = [];

			for (let p of pos) {
				const point = new Point(
					{
						pos: new Vector(
							(i + 1) * config.statueOffset + config.statueOffset / 2 + globalOffset,
							0,
						)
							.add(p)
							.add(new Vector(random.integer(-100, 50), random.integer(-100, 100))),
					},
					excludes,
				);

				point.once('enterviewport', () => {
					point.on('exitviewport', () => {
						if (point.pos.x < this.player.pos.x) point.kill();
					});
				});

				excludes.push(point.currentGraphicsName);

				this.add(point);
			}
		}
	}

	private async trainingComplete() {
		for (let timer of this.timers) timer.stop();
		this.player.stop();
		game.stopMusic();
		res.sounds.gameOver.play();
		await game.waitFor(2000);
		currentScreen.trainingComplete();
		startLives.set(this.lives);
		lastGameScore.set(get(score));
		await game.waitFor(1000);
		this.onDeactivate();
	}
}
