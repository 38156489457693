<script lang='ts'>
	import { fade } from 'svelte/transition';
	import MainButton from '../MainButton.svelte';
	import { onboarding } from '../../stores';
	import game from '../../game/game';
	import SoundButton from '../SoundButton.svelte';
	import Lives from '../Lives.svelte';
	import Image from '../Image.svelte';
	import { isTouchDevice } from '../../utils.js';

	const next = async () => {
		await game.play();
		$onboarding = false;
		window.exponea.track('opora_smkt', {
			event_action: 'game_step2_play',
		});

		requestAnimationFrame(() => {
			game.startCurrentScene();
		});
	};
</script>

<div class='onboarding' transition:fade>
	<div class='header'>
		<div class='placeholder'></div>
		<div class='lives-wrapper'>
			<Lives />
			<div class='informer'>
				<svg fill='none' viewBox='0 0 10 38' xmlns='http://www.w3.org/2000/svg'>
					<path d='M3.232 37C14.659 20.844 6.505 6.268 1 1' stroke='#FCFCFC' stroke-linecap='round'
								stroke-linejoin='round'
								stroke-width='2' />
				</svg>
				Попытки
			</div>
		</div>
		<div class='sound-wrapper'>
			<SoundButton />
		</div>
	</div>

	<div class='content'>
		<Image src='onboarding.png' />
		<div class='text'>
			<div class='title'>Вуаля! Можно начинать 👌🏼</div>
			{#if isTouchDevice()}
				Нажимайте на экран, чтобы не врезаться в статуи и не упасть. Если хотите получить приз, соберите 15 предметов,
				которые понравятся.
			{:else}
				Нажимайте на пробел, чтобы не врезаться в статуи и не упасть. Если хотите получить приз, соберите 15 предметов,
				которые понравятся.
			{/if}
		</div>
		<MainButton click={next}>Играть</MainButton>
	</div>
</div>

<style lang='scss'>
  .onboarding {
    padding-bottom: 24px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background: rgba(64, 64, 66, 1);
    pointer-events: auto;

    > .header {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;

      > .placeholder {
        width: 64px;
        height: 64px;
      }

      > .lives-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        > .informer {
          position: absolute;
          top: 100%;
          right: 0;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 20px;
          line-height: 140%;

          svg {
            width: 10px;
            height: 38px;
            margin-left: auto;
          }
        }
      }

      > .sound-wrapper {
        opacity: 0.5;
      }
    }

    > .content {
      padding-left: 32px;
      padding-right: 32px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      box-sizing: border-box;

      @include breakpoint-up($tablet) {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
      }

      > :global(picture) {
        width: 100%;
        height: auto;
        margin-top: auto;
        aspect-ratio: 1166 / 860;

        @include breakpoint-up($tablet) {
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      > .text {
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;

        > .title {
          margin-bottom: 10px;
          font-size: 22px;
          font-weight: 700;
          line-height: 30px;
        }
      }

      :global(.button) {
        margin-top: auto;
      }
    }
  }
</style>
