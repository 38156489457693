<script lang='ts'>
	import { currentObjectCount, lastObjectName } from '../stores';
	import Image from './Image.svelte';
</script>

<div class='points-counter'>
	{#if $lastObjectName}
		<div class='last-obj'>
			<Image src='{$lastObjectName}.png' />
		</div>
	{/if}
	<div class='text'>{$currentObjectCount}/ 15</div>
</div>

<style lang='scss'>
  .points-counter {
    position: fixed;
    right: 24px;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .last-obj {
      width: 68px;
      height: 68px;
      margin-bottom: 4px;
    }

    .text {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      text-align: right;
      color: #FFFFFF;
      text-shadow: -1px 0px 0px rgba(0, 0, 0, 0.1), 1px 3px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.06);
    }
  }
</style>
