<script lang='ts'>
	import { onMount } from 'svelte';

	let hide = false;

	onMount(() => {
		const screen = document.querySelector('.screen');
		screen.addEventListener('scroll', () => {
			hide = screen.scrollTop !== 0;
		});
	});
</script>

<div class='scroll-informer' class:-hide={hide}>
	<svg fill='none' viewBox='0 0 20 13' xmlns='http://www.w3.org/2000/svg'>
		<path d='m1.53 1.677 8.47 8.47 8.47-8.47' stroke='#fff' stroke-width='3.176' />
	</svg>
</div>

<style lang='scss'>
  @keyframes bounce-in {
    0% {
      transform: translateY(100%);
    }
    27% {
      transform: translateY(0%);
    }
    35% {
      transform: translateY(10.89%);
    }

    44% {
      transform: translateY(43.56%);
    }

    53% {
      transform: translateY(98.01%);
    }

    66% {
      transform: translateY(75.02%);
    }

    80% {
      transform: translateY(98.37%);
    }

    86% {
      transform: translateY(93.75%);
    }

    93% {
      transform: translateY(99.34%);
    }

    96% {
      transform: translateY(98.46%);
    }

    100% {
      transform: translateY(100%);
    }
  }

  .scroll-informer {
    width: 47px;
    height: 72px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 75px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    background: rgba(255, 72, 111, 1);
    transition: opacity 150ms ease-in;

    svg {
      width: 20px;
      height: 13px;
      animation: bounce-in 1s ease-in infinite;
    }

    &.-hide {
      opacity: 0;
    }
  }
</style>
