<script lang='ts'>
	import Template from './+template.svelte';
	import Slider from './Slider.svelte';
	import Text from './Text.svelte';
	import { onMount } from 'svelte';
	import { forms } from '../../texts';
	import { bag } from '../../stores';
	import type { BagCustomization } from '../../types';
	import Image from '../Image.svelte';

	let title;
	let subtitle;
	let slider;

	const setForm = (index) => {
		title = forms[index][0];
		subtitle = forms[index][1];
		(<BagCustomization>$bag).form = index;
	};

	const pageChange = (e) => setForm(e.detail);

	onMount(() => {
		setForm((<BagCustomization>$bag).form);
	});
</script>

<Template>
	<div class='title'>Выберите форму</div>
	<div class='slider'>
		<Slider initialPageIndex={$bag.form} on:pageChange={pageChange}>
			<div class='slide'>
				<Image src='bag/Form_01.png' />
			</div>
			<div class='slide'>
				<Image src='bag/Form_02.png' />
			</div>
			<div class='slide'>
				<Image src='bag/Form_03.png' />
			</div>
			<div class='slide'>
				<Image src='bag/Form_04.png' />
			</div>
			<div class='slide'>
				<Image src='bag/Form_05.png' />
			</div>
			<div class='slide'>
				<Image src='bag/Form_06.png' />
			</div>
		</Slider>
	</div>
	<Text {subtitle} {title} />
</Template>

<style lang='scss'>
  .slide {
    > :global(picture) {
      width: auto;
      height: 320px;
      margin: auto;
      aspect-ratio: 608 / 640;
    }
  }
</style>
