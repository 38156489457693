<script>
	import { links } from '../texts';
</script>

<div class='action-caption'>
	Предложение действует с 11.09.2023 по 1.11.2023, <a
	href='https://terms.samokat.ru/promo/Pravila_tochka_opory.pdf' target='_blank'>полные правила акции</a>. Продавец ООО
	«Умный ритейл», ОГРН 1177847261602, 192019,
	Санкт-Петербург, ул.Седова, дом.11, Литер А, Этаж 6, помещение 627. <a href='{links.policy}' target='_blank'>Политика
	конфиденциальности</a>
</div>

<style lang='scss'>
  .action-caption {
    margin-top: 24px;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;

    a {
      @include reset-link;
      text-decoration: underline;
    }
  }
</style>
