import Cookie from 'js-cookie';
import { random } from './game/utils';
import { names } from './texts';
import { attemptId, auth, bag, myBestResults, phone, score } from './stores';
import { get } from 'svelte/store';
import axios from 'axios';
import { COOKIES_NAME } from './constants';
import Bugsnag from '@bugsnag/js';

export const getRandomName = () => {
	const s = random.pickOne(['m', 'w']);

	const first = random.pickOne(names[s].first);
	const second = random.pickOne(names[s].second);

	return `${first} ${second}`;
};

export const isMobile = () => matchMedia('(max-width: 720px)').matches;
export const isTouchDevice = () => matchMedia('(pointer: coarse)').matches;

export const assetsUrl = url => {
	return `${import.meta.env.VITE_ASSETS_URL}${url}`;
};

export const isUserRegistered = () => {
	return get(auth);
};

export const isPromocodeReceived = () => {
	const cookie = Cookie.get(COOKIES_NAME);

	if (cookie) {
		const { promocode } = JSON.parse(cookie);

		return promocode || false;
	} else {
		return false;
	}
};

export const setPromocodeReceived = () => {
	setCookie('promocode', true);
};

export const sawResults = () => getCookie('sawResults');
export const setSawResults = () => setCookie('sawResults', true);

export class Api {
	static async getSession() {
		return axios
			.get('/api/session')
			.then(res => {
				if (res.status === 200 && res.data) {
					const { name, body, face, amulet, phone: dataPhone } = res.data;

					bag.set({
						name: name,
						face: parseInt(face),
						form: parseInt(body),
						totem: parseInt(amulet),
					});

					phone && phone.set(dataPhone);

					auth.set(true);

					return true;
				}

				return false;
			})
			.catch(e => {
				Bugsnag.notify(e.response.data.message);
				return false;
			});
	}

	static async register() {
		const customizationData = get(bag);

		return axios
			.post('/api/register', {
				name: customizationData.name,
				body: customizationData.form.toString(),
				face: customizationData.face.toString(),
				amulet: customizationData.totem.toString(),
			})
			.then(({ data }) => data?.ok || false)
			.catch(e => {
				Bugsnag.notify(e.response.data.message);
				return false;
			});
	}

	static async update() {
		const customizationData = get(bag);

		return axios
			.put('/api/user', {
				body: customizationData.form.toString(),
				face: customizationData.face.toString(),
				amulet: customizationData.totem.toString(),
				phone: get(phone),
			})
			.then(({ data }) => data?.ok || false)
			.catch(e => {
				Bugsnag.notify(e.response.data.message);
				return false;
			});
	}

	static async startGame(): Promise<void> {
		return new Promise((resolve, reject) => {
			window.addEventListener(
				'token',
				e => {
					axios
						.post('/api/start', {
							// @ts-ignore
							token: e.detail.token,
						})
						.then(({ data }) => {
							attemptId.set(data?.attemptId);
							resolve();
						})
						.catch(e => {
							Bugsnag.notify(e.response.data.message);
							reject();
						});
				},
				{
					once: true,
				},
			);

			window.grecaptcha.reset();
			window.grecaptcha.execute();
		});
	}

	static async finishGame(): Promise<void> {
		return new Promise((resolve, reject) => {
			window.addEventListener(
				'token',
				e => {
					axios
						.post('/api/finish', {
							result: get(score),
							attemptId: get(attemptId),
							// @ts-ignore
							token: e.detail.token,
						})
						.then(res => {
							if (res.status === 200 && res.data) {
								const { myBestResultPosition, myBestResult } = res.data;

								myBestResults.set({
									position: myBestResultPosition,
									result: myBestResult,
								});

								resolve();
							}

							reject();
						})
						.catch(e => {
							Bugsnag.notify(e.response.data.message);
							reject();
						});
				},
				{
					once: true,
				},
			);

			window.grecaptcha.reset();
			window.grecaptcha.execute();
		});
	}

	static async results() {
		return axios
			.get('/api/results')
			.then(res => {
				if (res.status === 200 && res.data) {
					return res.data;
				}
				return false;
			})
			.catch(e => {
				Bugsnag.notify(e.response.data.message);
				return false;
			});
	}
}

export const maskPhone = (phone: string) => {
	if (!phone) return '';

	const last4digits = phone.slice(-4);

	return `... ${last4digits.slice(0, 2)}-${last4digits.slice(-2)}`;
};

export const getCookie = (name: string) => {
	const cookie = Cookie.get(COOKIES_NAME);

	let cookieData = cookie ? JSON.parse(cookie) : {};

	return cookieData[name];
};

export const setCookie = (name: string, value: any) => {
	const cookie = Cookie.get(COOKIES_NAME);

	let cookieData = cookie ? JSON.parse(cookie) : {};

	cookieData = {
		...cookieData,
		[name]: value,
	};

	Cookie.set(COOKIES_NAME, JSON.stringify(cookieData), { expires: 365 });
};
