<div class='production'>
	Сделано в <a href='https://groznov.co/' target='_blank'>GROZNOV</a>®
</div>

<style lang='scss'>
  .production {
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;

    a {
      @include reset-link;
      text-decoration: underline;
    }
  }
</style>
