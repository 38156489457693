import { Vector } from 'excalibur';
import game from '../game';
import config from '../config';
import { random } from '../utils';

export default class StatueFactory {
	static create(count: number, globalOffset = 0) {
		let statues = [];

		const statueGapPosGrid = game.halfDrawHeight / 4;
		const offset = config.statueOffset;
		const gap = config.statueGap;

		for (let i = 0; i < count; i++) {
			const gapPos = statueGapPosGrid * random.integer(0, 2);

			statues.push([
				{
					pos: new Vector(globalOffset + offset * (i + 1), -statueGapPosGrid + gapPos + gap),
				},
				{
					pos: new Vector(globalOffset + offset * (i + 1), -statueGapPosGrid + gapPos - gap),
				},
			]);
		}

		return statues;
	}
}
