<script lang='ts'>
	import { score } from '../stores';
</script>

<div class='score'>
	<div class='text'>Очки</div>
	<div class='value'>{$score}</div>
</div>

<style lang='scss'>
  .score {
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > .text {
      padding: 0 8px 0 8px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0;
      text-align: right;
      border-radius: 9px 9px 0 9px;
      background: rgba(68, 22, 51, 0.69);
    }

    > .value {
      padding: 0 8px 0 8px;
      font-size: 39px;
      line-height: 49px;
      font-weight: 700;
      text-align: right;
      border-radius: 20px 0 20px 20px;
      background: rgba(68, 22, 51, 0.69);
    }
  }
</style>
