<script lang='ts'>
	import { fly } from 'svelte/transition';
	import { hints } from '../stores';
	import Hint from './Hint.svelte';
</script>

<div class='hints'>
	{#each $hints as hint}
		<div transition:fly='{{ y: -200, duration: 500 }}'>
			<Hint {...hint} />
		</div>
	{/each}
</div>

<style lang='scss'>
  .hints {
    position: fixed;
    top: 20px;
    left: 16px;
    right: 16px;

    @include breakpoint-up($tablet) {
      width: 720px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
