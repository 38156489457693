import { Random } from 'excalibur';

export const random = new Random();

export const easyTween = (cb: (progress: number) => void, duration: number): Promise<void> => {
	const now = performance.now();
	return new Promise(resolve => {
		requestAnimationFrame(function loop(time) {
			const progress = Math.min((time - now) / duration, 1);

			cb(progress);

			if (progress === 1) {
				return resolve();
			}

			requestAnimationFrame(loop);
		});
	});
};
