<script lang='ts'>
	import Image from './Image.svelte';

	export let img;
	export let text;
</script>

<div class='hint'>
	<div class='content'>
		<div class='img'>
			<Image src='{img}' />
		</div>
		<div class='text'>{text}</div>
	</div>
</div>

<style lang='scss'>
  .hint {
    margin-top: 20px;
    padding: 16px 24px 16px 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 24px;
    background-color: rgba(253, 242, 211, 1);
    color: #000;

    &:first-child {
      margin-top: 0;
    }

    .content {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .img {
        width: 48px;
        height: 48px;
        flex-shrink: 0;

        :global(img) {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
      }
    }
  }
</style>
