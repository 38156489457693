<script>
	import Carousel from 'svelte-carousel';
	import { res } from '../../game/res';

	export let initialPageIndex = 0;
	let slider;

	const showPrevPage = () => {
		res.sounds.custom.play();
		slider.goToPrev();
	};
	const showNextPage = () => {
		res.sounds.custom.play();
		slider.goToNext();
	};
</script>

<Carousel bind:this={slider} dots='{false}' {initialPageIndex} on:pageChange>
	<slot />

	<div class='prev' on:click={showPrevPage} slot='prev'>
		<svg fill='none' viewBox='0 0 12 18' xmlns='http://www.w3.org/2000/svg'>
			<path d='m2 2 7 7-7 7' stroke='#000' stroke-linecap='round' stroke-width='3' />
		</svg>
	</div>
	<div class='next' on:click={showNextPage} slot='next'>
		<svg fill='none' viewBox='0 0 12 18' xmlns='http://www.w3.org/2000/svg'>
			<path d='m2 2 7 7-7 7' stroke='#000' stroke-linecap='round' stroke-width='3' />
		</svg>
	</div>
</Carousel>

<style lang='scss'>
  .prev, .next {
    width: 48px;
    height: 48px;
    margin: auto;
    position: absolute;
    bottom: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
    background: #FDF2D3;
    z-index: 1;
    cursor: pointer;

    svg {
      width: 12px;
      height: 18px;
    }
  }

  .next {
    right: 16px;
  }

  .prev {
    left: 16px;

    svg {
      transform: scaleX(-1);
    }
  }
</style>
