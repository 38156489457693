<script lang='ts'>
	export let title;
	export let subtitle;
</script>

<div class='text'>
	<div class='title'>{title}</div>
	<div class='subtitle'>{subtitle}</div>
</div>

<style lang='scss'>
  .text {
    position: absolute;
    top: 445px;
    left: 32px;
    right: 32px;
    text-align: center;

    @include breakpoint-up($tablet) {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }

    .subtitle {
      margin-top: 16px;
      font-size: 20px;
      line-height: 24px;
    }
  }
</style>
