import type { ActorArgs } from 'excalibur';
import {
	Actor,
	CircleCollider,
	CollisionGroup,
	CollisionType,
	Engine,
	Keys,
	Vector,
} from 'excalibur';
import { res } from '../res';
import game from '../game';
import { pointsCollisionGroup, statueCollisionGroup } from '../collision';
import config from '../config';
import { GAME_EVENTS } from '../enum';
import type { BagCustomization } from '../../types';
import GameScene from '../partials/game-scene';
import LifeBoost from './life-boost';
import { progress, score } from '../../stores';
import Statue from './statue';
import Cloud from './cloud';
import PointsBoost from './points-boost';
import Point from './point';

export default class Player extends Actor {
	inputAvailable!: boolean;

	constructor(props: ActorArgs & { bag: BagCustomization }) {
		super({
			...props,
			width: 160,
			height: 160,
			collisionType: CollisionType.PreventCollision,
			collisionGroup: CollisionGroup.collidesWith([
				statueCollisionGroup,
				pointsCollisionGroup,
			]),
			collider: new CircleCollider({
				radius: 100,
			}),
			z: 2,
		});

		this.addGraphics(props.bag);
	}

	private _speed: number = config.scrollSpeed;

	get speed() {
		return this._speed;
	}

	set speed(val) {
		this._speed = Math.min(val, config.scrollSpeedMax);
	}

	update(engine: Engine, delta: number) {
		super.update(engine, delta);

		this.pos.y = Math.max(this.pos.y, -game.halfDrawHeight - this.height / 2);

		if (
			this.inputAvailable &&
			(this.pos.y < -config.playerYBounds || this.pos.y > config.playerYBounds)
		) {
			this.checkGameOver();
		}

		if (
			this.inputAvailable &&
			this.pos.y > engine.currentScene.camera.viewport.bottom - this.height / 2
		) {
			this.checkGameOver();
		}
	}

	onInitialize() {
		this.inputAvailable = false;
		this.registerEvents();
	}

	reset(startPos = 0, start: boolean) {
		this.pos.setTo(startPos, 0);
		start && this.start();
	}

	updateVel() {
		this.vel.x = this.speed;
	}

	jump() {
		game.lastInputTime = performance.now();
		this.vel.y = -config.jumpForce;
	}

	start() {
		this.inputAvailable = true;
		this.body.collisionType = CollisionType.Active;
		this.updateVel();
	}

	stop() {
		this.inputAvailable = false;
		this.body.collisionType = CollisionType.PreventCollision;
		this.vel = Vector.Zero;
	}

	private addGraphics(bag: BagCustomization) {
		const { form, face } = bag;

		const bodySprite = res.scene.getFrameSprite(
			`scene/customization/body/Form_0${form + 1}`,
		);
		const faceSprite = res.scene.getFrameSprite(
			`scene/customization/face/Emotion_0${face + 1}`,
		);

		this.graphics.add(bodySprite);
		this.graphics.add(faceSprite);
	}

	private async checkGameOver() {
		(<GameScene>this.scene).hurtPlayer();
		this.stop();

		await this.actions.blink(100, 100, 5).toPromise();

		if ((<GameScene>this.scene).lives === 0) {
			return this.scene.events.emit(GAME_EVENTS.GAME_OVER);
		}

		return this.scene.events.emit(GAME_EVENTS.RESTART);
	}

	private registerEvents() {
		game.input.keyboard.on('press', event => {
			if (event.key === Keys.Space) this.inputAvailable && this.jump();
		});

		game.input.pointers.on('down', () => this.inputAvailable && this.jump());

		this.on('collisionstart', e => {
			const target = e.other;

			if (target instanceof Statue || target instanceof Cloud) {
				res.sounds.fall.play();
				this.stop();
				this.checkGameOver();
			}

			if (target instanceof Point && !target.used) {
				this.scene.events.emit(GAME_EVENTS.SCORE);
				progress.add(target.name);
				target.use();
			}

			if (target instanceof LifeBoost) {
				(<GameScene>this.scene).addLives();
				target.use();
			}

			if (target instanceof PointsBoost) {
				score.boost();
				target.use();
			}
		});
	}
}
