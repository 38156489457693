<script lang='ts'>
	import Button from './Button.svelte';
	import { links } from '../texts';
	import { setCookie } from '../utils';
	import { policyAccepted } from '../stores';

	const accept = () => {
		setCookie('policy', true);
		policyAccepted.set(true);
	};
</script>

<div class='cookie-popup'>
	<div class='text'>
		На этом сайте мы используем cookie, чтобы всё работало как надо, а аналитика сайта соответствовала <a
		href='{links.policy}' target='_blank'>Политике
		конфиденциальности</a>
	</div>
	<Button on:click={accept}>
		ОК
	</Button>
</div>

<style lang='scss'>
  .cookie-popup {
    position: fixed;
    bottom: 24px;
    left: 16px;
    right: 16px;
    padding: 24px;
    border-radius: 24px;
    background: rgba(253, 242, 211, 1);
    box-sizing: border-box;

    @include breakpoint-up($tablet) {
      width: 640px;
      right: 20px;
      left: auto;
      display: flex;
      column-gap: 30px;

      :global(.button) {
        width: 112px;
      }
    }

    > .text {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 145%;
      text-align: center;
      color: #000000;
      opacity: 0.6;

      @include breakpoint-up($tablet) {
        margin-bottom: 0;
        text-align: left;
      }

      a {
        @include reset-link;
        text-decoration: underline;
      }
    }

    > :global(.button) {
      height: 50px;
      flex-shrink: 0;
    }
  }
</style>
