<script lang='ts'>
	import { fade } from 'svelte/transition';
	import { currentPopup, currentScreen, menu, policyAccepted, wrongOrientationScreen } from './stores';
	import { onMount } from 'svelte';
	import Hints from './lib/Hints.svelte';
	import Menu from './lib/Menu.svelte';
	import CookiePopup from './lib/CookiePopup.svelte';
	import WrongScreenOrientation from './lib/WrongScreenOrientation.svelte';

	let currentScreenComponent;
	let currentPopupComponent;

	const updateScreenComponent = () => {
		currentScreenComponent = $currentScreen;
	};

	const updatePopupComponent = () => {
		currentPopupComponent = $currentPopup;
	};

	onMount(() => {
		updateScreenComponent();
		updatePopupComponent();
	});
</script>

<main>
	{#if $currentScreen === currentScreenComponent}
		<div class='screen-container' on:outroend={updateScreenComponent} transition:fade>
			<svelte:component this={currentScreenComponent} />
		</div>
	{/if}

	{#if $currentPopup === currentPopupComponent}
		<div class='popup-container' on:outroend={updatePopupComponent} transition:fade>
			<svelte:component this={currentPopupComponent} />
		</div>
	{/if}

	<Hints />

	{#if $menu}
		<Menu />
	{/if}

	{#if !$policyAccepted}
		<CookiePopup />
	{/if}

	{#if $wrongOrientationScreen}
		<WrongScreenOrientation />
	{/if}
</main>

<style></style>
