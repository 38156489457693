<script>
	import Image from './Image.svelte';
</script>

<div class='loader'>
	<Image src='loader.png' />
</div>

<style lang='scss'>
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    :global(img) {
      width: 48px;
      height: 48px;
      animation: rotation 2s infinite linear
    }
  }
</style>
