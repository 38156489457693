import './styles/app.scss';
import App from './App.svelte';
import { Api } from './utils';
import Bugsnag from '@bugsnag/js';

Api.getSession().then(() => {
	new App({
		target: document.getElementById('app'),
	});
});

window.captchaOnSubmit = token => {
	window.dispatchEvent(
		new CustomEvent('token', {
			detail: { token },
		}),
	);
};

Bugsnag.start({ apiKey: 'c92cee7747ce1dac206b461cbc8f8b63' });
